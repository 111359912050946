/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR } from '@farewill/ui/tokens'
import { MODAL_BACKDROP } from '@/common/lib/zIndex'

const Overlay = ({ dismissible, dismissAction, show }) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (!dismissible) return
      if (event.key !== 'Escape') return
      dismissAction()
    },
    [dismissible, dismissAction]
  )

  const handleClick = useCallback(
    (event) => {
      if (!dismissible) return
      const target = event.target
      if (target === event.currentTarget) {
        // This was a direct click on the overlay, not a click that originated
        // from within the modal content, so run the dismiss action.
        dismissAction()
      }
    },
    [dismissible, dismissAction]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <StyledDiv $show={show} data-testid="ModalOverlay" onClick={handleClick} />
  )
}

Overlay.propTypes = {
  show: PropTypes.bool,
}

const StyledDiv = styled.div`
  background-color: ${COLOR.ACCENT.SECONDARY};
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s 0.15s;
  z-index: ${MODAL_BACKDROP};

  ${({ $show }) =>
    $show &&
    `
    opacity: 0.9;
    pointer-events: auto;
    transition: opacity 0.3s 0s;
  `}
`

export default Overlay
