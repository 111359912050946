import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'

import FooterLink from '../FooterLink'

const StyledLogoContainer = styled(FooterLink)`
  margin-right: ${GTR.M};
  margin-bottom: ${GTR.XS};
`

const StyledLogo = styled.img`
  height: 35px;
  margin-top: ${GTR.XXS};
`

const FooterHomeLink = ({
  loggedIn,
  logoVariant,
  hasWills,
  marketingSiteUrl,
}) => {
  const image =
    logoVariant === 'light' ? 'logo-text-white.svg' : 'logo-text-grey.svg'
  const url = loggedIn
    ? hasWills
      ? '/wills'
      : '/summary'
    : `${marketingSiteUrl}/wills/overview`

  return (
    <StyledLogoContainer isExternal={!loggedIn} url={url}>
      <StyledLogo alt="Farewill logo" src={`/images/logos/${image}`} />
    </StyledLogoContainer>
  )
}

FooterHomeLink.propTypes = {
  logoVariant: PropTypes.oneOf(['light', 'dark']),
  loggedIn: PropTypes.bool,
  hasWills: PropTypes.bool,
  marketingSiteUrl: PropTypes.string.isRequired,
}

FooterHomeLink.defaultProps = {
  loggedIn: false,
  logoVariant: 'light',
  hasWills: false,
}

export default FooterHomeLink
