import { keyHasValue } from 'startup/common/utilities'

export const inventoryItem = (state = {}, action) => {
  switch (action.type) {
    case 'UPSERT_INVENTORY_ITEM':
      if (action.id !== state._id) return state
      return Object.assign({ _id: action.id }, state, action.item)
    default:
      return state
  }
}

export const inventory = (state = [], action) => {
  switch (action.type) {
    case 'UPSERT_INVENTORY_ITEM': {
      const existingItem = state.find(keyHasValue('_id', action.id))
      if (existingItem) {
        return state.map((i) => inventoryItem(i, action))
      } else {
        return state.concat({ ...action.item, _id: action.id })
      }
    }
    case 'REMOVE_INVENTORY_ITEM':
      return state.filter(({ _id }) => _id !== action._id)
    default:
      return state
  }
}
