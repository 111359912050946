import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, H } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import Loader from 'ui/common/loader'

const StyledLoaderContainer = styled.div`
  position: relative;
  height: 40px;
`

const LoadingModal = ({ title }) => (
  <Wrapper padding={['XXL', 0]}>
    <StyledLoaderContainer>
      <Loader visible color={COLOR.WHITE} />
    </StyledLoaderContainer>
    {title && (
      <H centered color={COLOR.WHITE} margin={['M', 0, 0]} size="XL">
        {title}
      </H>
    )}
  </Wrapper>
)

LoadingModal.propTypes = {
  title: PropTypes.string,
}

export default LoadingModal
