import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from '@farewill/ui'

const ESC_KEYCODE = 27

class ClickOffWrapper extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.keyCode !== ESC_KEYCODE) {
      return
    }

    this.props.close() // eslint-disable-line react/destructuring-assignment
  }

  handleClick = () => {
    this.props.close() // eslint-disable-line react/destructuring-assignment
  }

  render() {
    const { className } = this.props

    return <Wrapper className={className} onClick={this.handleClick} />
  }
}

ClickOffWrapper.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
}

ClickOffWrapper.defaultProps = {
  className: '',
}

export default ClickOffWrapper
