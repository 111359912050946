import get from 'lodash/get'

export const showAlert = (level, ...args) => {
  switch (level) {
    case 'error':
      return showErrorAlert(...args)

    case 'success':
    default:
      return showSuccessAlert(...args)
  }
}

const showSuccessAlert = (message) => ({
  type: 'SHOW_SUCCESS_ALERT',
  payload: { message },
})

const showErrorAlert = (error, options) => {
  const DEFAULT_MESSAGE = 'Something went wrong'
  let message

  switch (typeof error) {
    case 'string':
      message = error
      break

    default: {
      const status = get(error, ['response', 'status'])

      switch (status) {
        case 429: // Rate limiting
        case 403: // CSRF
          message = get(error, ['response', 'data', 'message'])
          break

        default:
          message = error.reason || error.name
          break
      }

      break
    }
  }

  return {
    type: 'SHOW_ERROR_ALERT',
    payload: {
      message: message || DEFAULT_MESSAGE,
      error,
      persist: options?.persist,
    },
  }
}

export const hideAlert = () => ({
  type: 'HIDE_ALERT',
})
