import React, { useState, useEffect } from 'react'

import Loader from 'ui/common/loader'

const LOADER_REVEAL_DELAY = 500

const Fallback = () => {
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const loaderTimeout = setTimeout(
      () => setShowLoader(true),
      LOADER_REVEAL_DELAY
    )
    return () => clearTimeout(loaderTimeout)
  }, [])

  return <Loader visible={showLoader} />
}

Fallback.propTypes = {}

export default Fallback
