import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button, ExitIcon } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

const StyledButton = styled(Button.Underline)`
  align-self: center;
  color: ${COLOR.BLACK};
`

const SaveAndContinueLaterButton = ({ ...props }) => {
  return (
    <StyledButton
      color={'black'}
      icon={ExitIcon}
      tag={Link}
      to="/summary"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      Exit
    </StyledButton>
  )
}

export default SaveAndContinueLaterButton
