const initialState = {}

export const payment = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'ENABLE_PAYMENT':
      return { ...state, disabled: false }

    case 'SET_IDEMPOTENCY_KEY':
      return { ...state, idempotencyKey: payload }

    case 'SET_PAYMENT_CHARGES':
      return { ...state, charges: payload }

    case 'SET_VOUCHER_CODE_INPUT':
      return { ...state, voucherCodeInput: payload }

    default:
      return state
  }
}
