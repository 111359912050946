import React from 'react'
import styled from 'styled-components'
import { Button, EyeClosedIcon, EyeIcon, Wrapper } from '@farewill/ui'
import { FONT, GTR, COLOR } from '@farewill/ui/tokens'
import PropTypes from 'prop-types'

const PasswordMaskWrapper = styled(Wrapper)`
  display: flex;
  justify-content: flex-end;
  margin: ${(props) => props.margin};
`

const StyledButton = styled(Button.Plain)`
  width: 100px;
  font-size: ${FONT.SIZE.S};
  font-weight: ${FONT.WEIGHT.REGULAR};
  justify-content: start;
  align-self: flex-end;
  padding-left: ${GTR.S};
  &:focus {
    z-index: 1;
  }
  &:disabled {
    background-color: ${COLOR.BACKGROUND.FOG};
  }
`

const PasswordUnmaskButton = ({
  margin = 0,
  passwordIsUnmasked = false,
  togglePasswordMask,
  disabled = false,
}) => (
  <PasswordMaskWrapper margin={margin}>
    <StyledButton
      aria-hidden
      iconOnLeft
      disabled={disabled}
      icon={passwordIsUnmasked ? EyeClosedIcon : EyeIcon}
      iconMargin={8}
      type="button"
      onClick={togglePasswordMask}
    >
      {passwordIsUnmasked ? 'Hide' : 'Show'}
    </StyledButton>
  </PasswordMaskWrapper>
)

PasswordUnmaskButton.propTypes = {
  margin: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  passwordIsUnmasked: PropTypes.bool,
  togglePasswordMask: PropTypes.func,
  disabled: PropTypes.bool,
}

export default PasswordUnmaskButton
