import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledDropdownButton = styled(Button)`
  &:hover {
    background-color: initial;
  }

  &:focus {
    background-color: initial;
  }

  &:active {
    background-color: initial;
  }
`

const StyledTitle = styled.span`
  flex: 1;
`

const StyledChevron = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: ${COLOR.BLACK} transparent transparent transparent;
  margin-left: ${GTR.XS};
  transition: transform 0.3s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(-180deg);
  `};
`

const StyledDropdownButtonContent = styled.div`
  align-items: center;
  display: flex;
`

const DropdownButton = ({
  className,
  onClick,
  isOpen,
  children,
  stretch,
  ariaControls,
  ariaExpanded,
  dataCy,
}) => (
  <StyledDropdownButton
    aria-controls={ariaControls}
    aria-expanded={ariaExpanded}
    className={className}
    data-cy={dataCy}
    stretch={stretch}
    type="button"
    onClick={onClick}
  >
    <StyledDropdownButtonContent>
      <StyledTitle>{children}</StyledTitle>
      <StyledChevron isOpen={isOpen} />
    </StyledDropdownButtonContent>
  </StyledDropdownButton>
)

DropdownButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  stretch: PropTypes.bool,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  dataCy: PropTypes.string,
}

DropdownButton.defaultProps = {
  className: '',
  stretch: false,
  ariaControls: undefined,
  ariaExpanded: undefined,
}

export default DropdownButton
