import { createSelector } from 'reselect'
import moment from 'moment'

import { getParsedCookie } from 'lib/storage/cookies/helpers'
import { getCookieConfig } from 'lib/storage/cookies/config'

export const getUser = (state) => state.user

export const getUserId = createSelector(getUser, (user) => user._id)

export const getExecutors = createSelector(getUser, (user) => user.executors)

export const getRelationshipId = createSelector(
  getUser,
  (user) => user.relationshipId
)

export const getResubmissionGracePeriodEndDate = createSelector(
  getUser,
  ({ resubmissionGracePeriodEndsAt }) => resubmissionGracePeriodEndsAt
)

export const getResubmissionGracePeriodDaysRemaining = (state) => {
  const resubmissionGracePeriodEndDate =
    getResubmissionGracePeriodEndDate(state)
  const today = moment().startOf('day')
  const periodEndDate = moment(resubmissionGracePeriodEndDate).startOf('day')
  const diffInDays = periodEndDate.diff(today, 'days')
  return diffInDays
}

export const getSubscriptionOfferPeriodDaysRemaining = (state) => {
  const period = getSubscriptionOfferPeriod(state)
  const today = moment().startOf('day')
  const periodEndDate = moment(period.ends).startOf('day')
  const daysLeft = periodEndDate.diff(today, 'days')
  return daysLeft
}

export const getSubscriptionOfferPeriod = createSelector(
  getUser,
  ({ subscriptionOfferPeriodStartsAt, subscriptionOfferPeriodEndsAt }) => {
    return {
      starts: subscriptionOfferPeriodStartsAt,
      ends: subscriptionOfferPeriodEndsAt,
    }
  }
)

export const getUserEmail = createSelector(getUser, (user) => user.email)

export const getUserName = createSelector(
  getUser,
  (user) => user.preferredName || user.fullLegalName
)

export const getUserPhoneNumber = createSelector(
  getUser,
  (user) => user.phoneNumber
)

export const getUserPreferredName = createSelector(
  getUser,
  (user) => user.preferredName
)

export const getInviteDiscountPercentage = createSelector(
  getUser,
  (user) => user.invite.discount * 100
)

export const isCampaignPageReferral = createSelector(
  getUser,
  (user) => !!user.autoAppliedVoucherCode
)

export const isExecutor = (contact) =>
  createSelector(getExecutors, (executors) => executors.includes(contact._id))

export const isInResubmissionGracePeriod = (state) => {
  const resubmissionGracePeriodEndDate =
    getResubmissionGracePeriodEndDate(state)
  const now = new Date()
  const periodEndDate = new Date(resubmissionGracePeriodEndDate)
  return now < periodEndDate
}

export const isLoggedInWillsUser = createSelector(getUser, (user) => !!user._id)

export const isPartner = (contact) =>
  createSelector(
    getRelationshipId,
    (relationshipId) => relationshipId === contact._id
  )

/**
 * NOTE: Do not access this directly. Instead, use the `getBranding` selector
 * below which includes logic to fallback to looking up the branding to use
 * by reading the referral cookie if the user is not logged in.
 */
const getUserBranding = createSelector(getUser, (user) => user.branding)

/**
 * Determines which branding to use based on the user's state.
 *
 * If the user is already logged in, then the branding is read from the branding
 * field on the user document in Mongo. If the user is not logged in, then the
 * branding is read from the referral cookie to ensure we show the correct
 * branding in the sign up flow.
 *
 * NOTE: There is an equivalent server-side implementation of this selector in
 * server/helpers/branding.js. These implementations should be kept in-sync.
 *
 * @param {*} state Root Redux state object
 * @returns string | null
 */
export const getBranding = createSelector(
  [isLoggedInWillsUser, getUserBranding],
  (isLoggedIn, userBranding) => {
    if (!isLoggedIn) {
      const { CookieNames } = getCookieConfig()
      const referralCookie = getParsedCookie(CookieNames.WILLS_REFERRAL_PARAMS)
      return referralCookie?.branding || null
    }

    return userBranding || null
  }
)

export const getRedemptionPartner = createSelector(
  getUser,
  (user) => user.redemptionPartnerName
)

export const hasUserAnsweredMarketingPermissions = createSelector(
  getUser,
  (user) => user.redemptionPartnerMarketingPermissionsGiven !== undefined
)

export const exportedForTesting = {
  getUserBranding,
}
