const initialState = undefined

export const canGenerateWills = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_CAN_GENERATE_WILLS':
      return payload

    default:
      return state
  }
}
