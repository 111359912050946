import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(
    /* webpackChunkName: "RequestResetPasswordHelp" */ 'ui/routes/request-reset-password-help/routes'
  )

const RequestResetPasswordHelpRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

RequestResetPasswordHelpRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType).isRequired,
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

RequestResetPasswordHelpRouteHandler.displayName =
  'RequestResetPasswordHelpRouteHandler'

export default RequestResetPasswordHelpRouteHandler
