import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(/* webpackChunkName: "Subscription" */ 'ui/routes/subscription/routes')

const SubscriptionRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

SubscriptionRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType),
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

SubscriptionRouteHandler.displayName = 'SubscriptionRouteHandler'

export default SubscriptionRouteHandler
