import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, CrossIcon, Wrapper, P } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'

import { ALERT as ALERT_Z_INDEX } from 'lib/zIndex'

const StyledWrapper = styled(Wrapper)`
  z-index: ${ALERT_Z_INDEX};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.3s, opacity 0.3s;

  ${({ $visible }) =>
    $visible &&
    `
      transform: translateY(0%);
      opacity: 1;
    `};
`

const StyledAlert = styled(Wrapper)`
  display: flex;
  background-color: ${COLOR.WHITE};
  border: 2px solid ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};

  ${({ $error }) =>
    $error &&
    `
      color: ${COLOR.WHITE};
      border-color: ${COLOR.GREY.DARK};
      background-color: ${COLOR.GREY.DARK};
    `};
`

const StyledButton = styled(Button)`
  height: fit-content;
  position: relative;
  top: -${GTR.XS};
  right: -${GTR.S};
`

const getTimeoutLength = (alertType) => {
  if (alertType === 'success') return 2500
  if (alertType === 'error') return 4000
  return 5000
}

const Alert = ({ alert, hideAlert }) => {
  const { type, visible, persist, message } = alert

  useEffect(() => {
    let timeout
    if (visible && !persist) {
      timeout = setTimeout(hideAlert, getTimeoutLength(type))
    }

    return () => clearTimeout(timeout)
  }, [alert])

  return (
    alert && (
      <StyledWrapper $visible={visible} padding="M">
        <StyledAlert
          $error={type === 'error'}
          aria-label={message}
          maxWidthInColumns={5}
          padding={['S', 'M']}
          role={type === 'error' ? 'alert' : 'status'}
        >
          <P margin={0}>{message}</P>
          {persist && (
            <StyledButton
              flush
              icon={<CrossIcon color={COLOR.WHITE} />}
              iconMargin={0}
              onClick={hideAlert}
            />
          )}
        </StyledAlert>
      </StyledWrapper>
    )
  )
}

Alert.propTypes = {
  alert: PropTypes.shape({
    type: PropTypes.string,
    visible: PropTypes.bool,
    message: PropTypes.string,
    persist: PropTypes.bool,
  }).isRequired,
  hideAlert: PropTypes.func.isRequired,
}

export default Alert
