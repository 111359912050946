import { connect } from 'react-redux'

import { hasSomeRequest } from 'startup/common/helpers'
import history from 'startup/common/history'
import { SIGN_UP_START_PATH } from 'ui/routes/start/paths'

import { isLoggedIn } from '@/common/redux/account/selectors'
import { showAlert } from '@/common/redux/alert/actionCreators'
import { getCookieBannerFeatureEnabled } from '@/common/redux/config/selectors'
import { trackingIdentify } from '@/common/redux/tracking/actionCreators'
import { asyncUserUpdate } from '@/common/redux/user/actionCreators'
import { isLoggedInWillsUser, getUser } from '@/common/redux/user/selectors'

import MainLayout from './component'

const mapStateToProps = (state) => {
  return {
    isLoggedInWillsUser: isLoggedInWillsUser(state),
    isLoggedIn: isLoggedIn(state),
    isModalOpen: !state.modal.hide && !!state.modal.component,
    rootUrl: state.config.ROOT_URL,
    isProduction: state.config.RELEASE_ENV === 'production',
    isCookieBannerFeatureEnabled: getCookieBannerFeatureEnabled(state),
    userId: getUser(state)._id,
    userExptLinearWillsFlowTreatment: getUser(state).exptLinearWillsFlow,
  }
}

const mapDispatchToProps = {
  trackUser: () => (dispatch, getState) => {
    const state = getState()
    const user = getUser(state)

    dispatch(trackingIdentify(user))
  },

  showAlert:
    ({ successAlert, errorAlert }) =>
    (dispatch) => {
      history.replace('/')

      if (successAlert) {
        return dispatch(showAlert('success', successAlert))
      }

      if (errorAlert) {
        return dispatch(showAlert('error', errorAlert))
      }
    },

  redirectToOnboardingFlow: () => () => {
    history.replace(SIGN_UP_START_PATH)
  },

  saveTreatment: (values) => (dispatch, getState) => {
    const state = getState()
    const user = getUser(state)

    /** Return early if there is no user or user is an empty object (eg upon logout),
     * or if user has already been updated with the treatment
     */
    if (!user || Object.keys(user).length === 0 || user.exptLinearWillsFlow)
      return

    /** Return early if saveTreatment request is already in progress */
    const isRequestPending = hasSomeRequest(getState(), [
      'users/update',
      '/accounts/load-data',
    ])
    if (isRequestPending) return

    const { exptLinearWillsFlow } = values
    dispatch(
      asyncUserUpdate({
        exptLinearWillsFlow,
      })
    )
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
