import * as yup from 'yup'

import { FIELD_NAMES } from './constants'

export const schema = yup.object({
  [FIELD_NAMES.EMAIL]: yup
    .string()
    .trim()
    .email('Enter an email address in the correct format')
    .required('Please enter your email address'),
  [FIELD_NAMES.PASSWORD]: yup
    .string()
    .trim()
    .required('Please enter your password'),
})
