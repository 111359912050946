import { createSelector } from 'reselect'
import { WillCheckingStatus } from 'types/wills'

export const getWills = (state) => state.wills

export const hasWills = createSelector(
  getWills,
  (wills) => wills && wills.length > 0
)

export const getWillById = (willId) =>
  createSelector(getWills, (wills) => wills.find((will) => will._id === willId))

export const getLatestWill = createSelector(getWills, (wills) => wills[0])

export const getPassedWills = createSelector(getWills, (wills) =>
  wills.filter((will) => will.checkingStatus === WillCheckingStatus.Passed)
)

export const checkLatestWillHasPassed = createSelector(
  getLatestWill,
  (will) => will && will.checkingStatus === WillCheckingStatus.Passed
)

export const latestWillDownloaded = createSelector(
  getLatestWill,
  (will) => will && !!will.downloadedAt
)

export const hasDownloadableWill = createSelector(getWills, (wills) => {
  if (!wills) return false

  return wills.some(
    ({ checkingStatus }) =>
      checkingStatus === WillCheckingStatus.Passed ||
      checkingStatus === undefined
  )
})
