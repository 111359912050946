import React from 'react'
import PropTypes from 'prop-types'

const MarketingSiteLink = ({ baseUrl, path, children, className }) => (
  <a
    className={className}
    href={`${baseUrl}${path}`}
    rel="noopener"
    // eslint-disable-next-line react/jsx-no-target-blank
    target="_blank"
  >
    {children}
  </a>
)

MarketingSiteLink.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

MarketingSiteLink.defaultProps = {
  className: null,
}

export default MarketingSiteLink
