export const printAndSend = (state = {}, { type, payload }) => {
  switch (type) {
    case 'UPDATE_DELIVERY_ADDRESS':
      return {
        ...state,
        deliveryAddress: payload,
      }
    default:
      return state
  }
}
