import {
  ONLINE_WILL_SIGN_UP_PATH,
  TELEPHONE_WILL_SIGN_UP_PATH,
} from 'ui/routes/start/paths'

export const ONLINE_WILL = {
  label: 'online',
  queryParam: 'online',
  inSentence: 'online',
  path: ONLINE_WILL_SIGN_UP_PATH,
}

export const TELEPHONE_WILL = {
  label: 'phone',
  queryParam: 'telephone',
  inSentence: 'over the phone',
  path: TELEPHONE_WILL_SIGN_UP_PATH,
}

export const SERVICE_OPTIONS = {
  [ONLINE_WILL.label]: ONLINE_WILL,
  [TELEPHONE_WILL.label]: TELEPHONE_WILL,
}

export const ACCOUNT_PRODUCTS = {
  FUNERAL_PLAN: 'funeral_plan',
  ONLINE_WILL: 'online_will',
}
