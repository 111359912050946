export const requestPending = (name) => {
  return {
    type: 'REQUEST_PENDING',
    name,
  }
}

export const requestCompleted = (name) => {
  return {
    type: 'REQUEST_COMPLETED',
    name,
  }
}

export const requestError = (name, error, message) => {
  return {
    type: 'REQUEST_ERROR',
    name,
    error,
    message,
  }
}
