export const estateBeneficiary = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_ESTATE_BENEFICIARY':
      return {
        _id: action._id,
        share: action.share || 0,
        beneficiaryId: action.beneficiaryId,
        beneficiaryType: action.beneficiaryType,
        userId: action.userId,
        inheritancePrecedence: action.inheritancePrecedence,
        targetBeneficiaryId: action.targetBeneficiaryId,
      }
    case 'UPDATE_ESTATE_BENEFICIARY':
      if (state._id !== action._id) return state
      return Object.assign(state, action.data)
    default:
      return state
  }
}

export const estateBeneficiaries = (state = [], action) => {
  switch (action.type) {
    case 'ADD_ESTATE_BENEFICIARY':
      return [...state, estateBeneficiary({}, action)]
    case 'REMOVE_ESTATE_BENEFICIARY':
      return state.filter(
        (estateBeneficiary) => estateBeneficiary._id !== action._id
      )
    case 'UPDATE_ESTATE_BENEFICIARY':
      return state.map((estateBen) => estateBeneficiary(estateBen, action))
    default:
      return state
  }
}
