import { splitReducer } from '@splitsoftware/splitio-redux'
import app from 'startup/common/reducers'

import { alert } from './redux/alert/reducer'
import { beneficiaries } from './redux/beneficiaries/reducer'
import { bespokeCapacityChecks } from './redux/bespokeCapacityChecks/reducer'
import { canGenerateWills } from './redux/canGenerateWills/reducer'
import { charities } from './redux/charities/reducer'
import { charitySearch } from './redux/charitySearch/reducer'
import { churches } from './redux/churches/reducer'
import { config } from './redux/config/reducer'
import { estateBeneficiaries } from './redux/estateBeneficiaries/reducer'
import { estateSplitIssue } from './redux/estateSplitIssue/reducer'
import { executorChoices } from './redux/executorChoices/reducer'
import { funeralPlan } from './redux/funeralPlan/reducer'
import { funeralWishes } from './redux/funeralWishes/reducer'
import { inventory } from './redux/inventory/reducer'
import { isInGuidedFlow } from './redux/isInGuidedFlow/reducer'
import { linkedUserData } from './redux/linkedUserData/reducer'
import { login } from './redux/login/reducer'
import { modal } from './redux/modal/reducer'
import { referralParams } from '@/common/redux/referralParams/reducer'
import { payment } from './redux/payment/reducer'
import { pets } from './redux/pets/reducer'
import { printAndSend } from './redux/printAndSend/reducer'
import { properties } from './redux/properties/reducer'
import { query } from './redux/query/reducer'
import { referralUses } from './redux/referralUses/reducer'
import { requestsPending } from './redux/requestsPending/reducer'
import { showAccountMenu } from './redux/showAccountMenu/reducer'
import { showMobileNav } from './redux/showMobileNav/reducer'
import { singleSignOn } from './redux/singleSignOn/reducer'
import { start } from './redux/start/reducer'
import { subscriptions } from './redux/subscriptions/reducer'
import { topOfPageError } from './redux/topOfPageError/reducer'
import { user, initialUser } from './redux/user/reducer'
import { wills } from './redux/wills/reducer'
import { willHasChanged } from './redux/willHasChanged/reducer'

export const initialState = {
  isInGuidedFlow: false,
  showMobileNav: false,
  showAccountMenu: false,
  modal: {},
  alert: {},
  requestsPending: [],
  charitySearch: {},
  singleSignOn: {},
  user: initialUser,
  referralUses: 0,
}

const rootReducer = (state = initialState, action) => {
  const newState = app(state, action)

  return {
    ...newState,
    alert: alert(newState.alert, action),
    beneficiaries: beneficiaries(newState.beneficiaries, action),
    bespokeCapacityChecks: bespokeCapacityChecks(
      newState.bespokeCapacityChecks,
      action
    ),
    canGenerateWills: canGenerateWills(newState.canGenerateWills, action),
    charities: charities(newState.charities, action),
    charitySearch: charitySearch(newState.charitySearch, action),
    churches: churches(newState.churches, action),
    config: config(newState.config, action),
    estateBeneficiaries: estateBeneficiaries(
      newState.estateBeneficiaries,
      action
    ),
    estateSplitIssue: estateSplitIssue(newState.estateSplitIssue, action),
    executorChoices: executorChoices(newState.executorChoices, action),
    funeralPlan: funeralPlan(newState.funeralPlan, action),
    funeralWishes: funeralWishes(newState.funeralWishes, action),
    inventory: inventory(newState.inventory, action),
    isInGuidedFlow: isInGuidedFlow(newState.isInGuidedFlow, action),
    linkedUserData: linkedUserData(newState.linkedUserData, action),
    login: login(newState.login, action),
    modal: modal(newState.modal, action),
    payment: payment(newState.payment, action),
    pets: pets(newState.pets, action),
    printAndSend: printAndSend(newState.printAndSend, action),
    properties: properties(newState.properties, action),
    query: query(newState.query, action),
    referralUses: referralUses(newState.referralUses, action),
    referralParams: referralParams(newState.referralParams, action),
    requestsPending: requestsPending(newState.requestsPending, action),
    showAccountMenu: showAccountMenu(newState.showAccountMenu, action),
    showMobileNav: showMobileNav(newState.showMobileNav, action),
    singleSignOn: singleSignOn(newState.singleSignOn, action),
    splitio:
      action.payload !== null // Temporay workaround for https://github.com/splitio/redux-client/issues/121
        ? splitReducer(newState.splitio, action)
        : newState.splitio,
    start: start(newState.start, action),
    subscriptions: subscriptions(newState.subscriptions, action),
    topOfPageError: topOfPageError(newState.topOfPageError, action),
    user: user(newState.user, action),
    wills: wills(newState.wills, action),
    willHasChanged: willHasChanged(newState.willHasChanged, action),
  }
}

export default rootReducer
