import PropTypes from 'prop-types'

import { BeneficiaryType } from './beneficiaries'
import { PetType } from './pets'

export const DependantType = PropTypes.oneOfType([
  PropTypes.shape({
    ...BeneficiaryType,
    isOverEighteen: PropTypes.oneOf([false]).isRequired,
  }),
  PropTypes.shape(PetType),
])
