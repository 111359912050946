import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ExitIcon, Button, Divider, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import { HEADER as HEADER_Z_INDEX } from 'lib/zIndex'

const OFFSETS_OVERRIDES = css`
  ${({ offsets }) =>
    Object.entries(offsets).reduce(
      (memo, [direction, value]) => `${memo} ${direction}: ${value};`,
      ''
    )};
`

const StyledMenu = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  color: ${COLOR.BLACK};
  border: solid 1px ${COLOR.GREY.LIGHT};
  width: max-content;
  position: absolute;
  z-index: 1;
  ${OFFSETS_OVERRIDES};
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top 14px;
  z-index: ${HEADER_Z_INDEX};

  &:after, &:before {
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-bottom-color: ${COLOR.WHITE};
    border-width: 13px;
    margin-left: -13px;
  }

  &:before {
    border-bottom-color: ${COLOR.GREY.LIGHT};
    border-width: 14px;
    margin-left: -14px;
  }

  ${screenMax.m`
    display: none;
  `};
`

const StyledListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  line-height: 1.6;
  white-space: nowrap;
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  line-height: 1.6;
  white-space: nowrap;
`

const StyledLogoutButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: none;
  line-height: 1.6;
  white-space: nowrap;

  &:hover {
    background-color: initial;
  }

  &:focus {
    outline: 0;
    background-color: initial;
  }

  &:active {
    outline: 0;
    background-color: initial;
  }
`

const AccountLink = ({ link }) => {
  if (link.external) {
    return <StyledExternalLink href={link.url}>{link.name}</StyledExternalLink>
  }
  return (
    <StyledLink
      to={link.url}
      onClick={() => {
        if (link.onClick) link.onClick()
      }}
    >
      {link.name}
    </StyledLink>
  )
}

const AccountMenu = ({ offsets, logout, accountLinks }) => (
  <StyledMenu data-cy="account-menu-popup" offsets={offsets}>
    <Wrapper padding="M">
      <ul>
        {accountLinks.map((link, i) => (
          <StyledListItem key={i}>
            <AccountLink link={link} />
            {link.dividerBelow && <Divider margin={['XS', 0, 0]} />}
          </StyledListItem>
        ))}
        <StyledListItem>
          <Divider margin={[0, 0, 'XS']} />
          <StyledLogoutButton icon={<ExitIcon />} onClick={logout}>
            Save and logout
          </StyledLogoutButton>
        </StyledListItem>
      </ul>
    </Wrapper>
  </StyledMenu>
)

const LinkType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  external: PropTypes.bool,
  onClick: PropTypes.func,
  dividerBelow: PropTypes.bool,
})

AccountLink.propTypes = {
  link: LinkType.isRequired,
}

AccountMenu.propTypes = {
  offsets: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  logout: PropTypes.func,
  accountLinks: PropTypes.arrayOf(LinkType).isRequired,
}

AccountMenu.defaultProps = {
  offsets: {},
  logout: null,
}

export default AccountMenu
