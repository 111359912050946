import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input } from '@farewill/ui'

import PasswordUnmaskButton from './components/PasswordUnmaskButton'
import PasswordStrengthBar from './components/PasswordStrengthBar'

const StyledInput = styled(Input)`
  margin-bottom: 0;
`

const PasswordInput = ({
  autoComplete,
  error,
  id,
  label,
  name,
  value,
  onChange,
  onBlur,
  showPasswordStrength,
}) => {
  const [passwordIsUnmasked, setPasswordIsUnMasked] = useState(false)

  return (
    <>
      <StyledInput
        autoComplete={autoComplete}
        error={error}
        id={id}
        label={label || 'Password (minimum 10 characters)'}
        name={name}
        type={passwordIsUnmasked ? 'text' : 'password'}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      <PasswordUnmaskButton
        margin={[0, 0, 'M', 0]}
        passwordIsUnmasked={passwordIsUnmasked}
        togglePasswordMask={() => setPasswordIsUnMasked(!passwordIsUnmasked)}
      />
      {showPasswordStrength && <PasswordStrengthBar password={value} />}
    </>
  )
}

PasswordInput.propTypes = {
  autoComplete: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  showPasswordStrength: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

PasswordInput.defaultProps = {
  autoComplete: 'current-password',
  showPasswordStrength: false,
}

export default PasswordInput
