import { createSelector } from 'reselect'

import { parseBool, penceToPounds } from 'startup/common/utilities'

export const getConfig = (state) => {
  if (!['development', 'test'].includes(state.config.RELEASE_ENV)) {
    return state.config
  }

  // Allow feature flags to be overridden via sessionStorage so that we can run
  // Cypress tests on both variants of a feature flag’s behaviour.
  return new Proxy(state.config, {
    get(target, prop) {
      // Restrict overriding to feature flags.
      if (!prop.startsWith('FF_')) {
        return target[prop]
      }

      // Check for override, and if present, return the override value.
      const override = sessionStorage.getItem(prop)
      if (override !== null) {
        // eslint-disable-next-line no-console
        console.debug(
          `[config] using feature flag override for flag ${prop}: ${override}`
        )
        // Note: Since we've restricted overrides to feature flags which are
        // (currently) always boolean values we know we need to parse as boolean
        // here. If we ever need to override a non-boolean value we'll need to
        // revisit this logic.
        return parseBool(override)
      }

      // No override for this flag, return its original value.
      return target[prop]
    },
  })
}

export const getTelephoneWillsAppointmentUrl = createSelector(
  getConfig,
  (config) => config.TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL
)

export const getCouplesWillPriceInPounds = createSelector(getConfig, (config) =>
  penceToPounds(
    config.ONLINE_WILL_PRICE + config.ONLINE_WILL_SUBSEQUENT_PRICE,
    0
  )
)
export const getCouplesWillSavingsInPounds = createSelector(
  getConfig,
  (config) =>
    penceToPounds(
      config.ONLINE_WILL_PRICE - config.ONLINE_WILL_SUBSEQUENT_PRICE,
      0
    )
)

export const getSubscriptionPriceInPounds = createSelector(
  getConfig,
  (config) => penceToPounds(config.ONLINE_WILL_SUBSCRIPTION_PRICE, 0)
)

export const getSubscriptionTrialPeriodDays = createSelector(
  getConfig,
  (config) => config.ONLINE_WILL_SUBSCRIPTION_TRIAL_PERIOD_DAYS
)

export const getResubmissionGracePeriodDays = createSelector(
  getConfig,
  (config) => config.ONLINE_WILL_RESUBMISSION_GRACE_PERIOD_DAYS
)

export const getSingleWillPriceInPounds = createSelector(getConfig, (config) =>
  penceToPounds(config.ONLINE_WILL_PRICE, 0)
)

// Feature flags

export const getCharitySearchUiEnabled = createSelector(
  getConfig,
  (config) => config.FF_CHARITY_SEARCH_UI_ENABLED
)

export const getCookieBannerFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_COOKIE_BANNER_ENABLED
)

export const getCustomResiduaryGiftPromptFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_CUSTOM_RESIDUARY_GIFT_PROMPT_ENABLED
)

export const getFuneralPlanGuideFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_FUNERAL_PLAN_GUIDE_ENABLED
)

export const getFuneralPlanBannerFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_FUNERAL_PLAN_BANNER_ENABLED
)

export const getShippedPrintedWillPriceInPounds = createSelector(
  getConfig,
  (config) =>
    penceToPounds(
      config.PRINTED_WILL_DISPLAY_PRICE_IN_PENCE +
        config.PRINTED_WILL_SHIPPING_DISPLAY_PRICE_IN_PENCE
    )
)

export const getReferBusinessOwnersToTelewillsFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_REFER_BUSINESS_OWNERS_TO_TELEWILLS_ENABLED
)

export const getReferOverseasAssetsToTelewillsFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_REFER_OVERSEAS_ASSETS_TO_TELEWILLS_ENABLED
)

export const getCallbackAnytimeFeatureEnabled = createSelector(
  getConfig,
  (config) => config.FF_CALLBACK_ANYTIME_ENABLED
)

export const getFuneralWishesExperimentEnabled = createSelector(
  getConfig,
  (config) => config.FF_FUNERAL_WISHES_AB_ENABLED
)

export const getMgmWillsOfferUpdateEnabled = createSelector(
  getConfig,
  (config) => config.FF_MGM_WILLS_OFFER_UPDATE_ENABLED
)
