/**
 * @fileoverview For now this is mostly copy of the equivalent file from the
 * farewill.com repo.
 *
 * Ideally we'd abstract this (and the other cookie helpers used by both repos
 * in ./helpers.js) into a standalone private NPM module which can then be used
 * by both apps.
 */
import { getCookie } from './helpers'
import { getCookieConfig } from './config'

export const getCookieConsent = () => {
  const cookie = getCookie('cookieyes-consent')
  if (!cookie) {
    return null
  }

  const result = cookie.split(',').reduce((acc, pair) => {
    const [key, value] = pair.split(':')
    acc[key] = value
    return acc
  }, {})

  return result
}

const getCookieMap = (cookiesByCategory) => {
  const map = new Map()
  Object.entries(cookiesByCategory).forEach(([category, cookies]) => {
    cookies.forEach((cookie) => {
      map.set(cookie, category)
    })
  })
  return map
}

const getCategoryForCookie = (cookieName) => {
  const { CookieCategories } = getCookieConfig()
  const cookieMap = getCookieMap(CookieCategories)
  return cookieMap.get(cookieName) || null
}

export const userGrantedConsentForCategory = (category) => {
  const consentMap = getCookieConsent()
  if (!consentMap) {
    return false
  }
  return consentMap[category] === 'yes'
}

export const hasUserAgreedToCookie = (name) => {
  const cookieCategory = getCategoryForCookie(name)
  if (!cookieCategory) {
    return false
  }
  const consented = userGrantedConsentForCategory(cookieCategory)
  return consented
}
