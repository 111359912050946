import React from 'react'
import { P } from '@farewill/ui'
import PropTypes from 'prop-types'

import { API_ERROR_KEY } from '../constants'

const Errors = ({ errors, label, renderAs = 'list' }) => {
  const messages = Object.entries(errors).map(([id, text]) => ({ id, text }))

  const handleClick = (id) => (event) => {
    event.preventDefault()
    document.getElementById(id)?.focus()
  }

  if (renderAs === 'paragraphs') {
    return (
      <>
        {messages.map(({ id, text }, index) => (
          <P key={index}>
            <a href={`#${id}`} onClick={handleClick(id)}>
              {text}
            </a>
          </P>
        ))}
      </>
    )
  }

  return (
    <>
      {messages.length && (
        <P margin={[0, 0, 'XS']}>
          {label ?? 'Please fix these errors to continue:'}
        </P>
      )}
      <ul>
        {messages.map(({ id, text }, index) => (
          <li key={index}>
            {id === API_ERROR_KEY ? (
              text
            ) : (
              <a href={`#${id}`} onClick={handleClick(id)}>
                {text}
              </a>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

Errors.propTypes = {
  errors: PropTypes.object.isRequired,
  label: PropTypes.string,
  renderAs: PropTypes.oneOf(['list', 'paragraphs']),
}

export default Errors
