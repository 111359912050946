import { connect } from 'react-redux'

import { isLoggedInWillsUser } from '@/common/redux/user/selectors'
import { hasWills } from '@/common/redux/wills/selectors'

import FooterHomeLink from './component'

const mapStateToProps = (state) => ({
  loggedIn: isLoggedInWillsUser(state),
  hasWills: hasWills(state),
  marketingSiteUrl: state.config.MARKETING_SITE_URL,
})

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(FooterHomeLink)
