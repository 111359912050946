import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { BORDER } from '@farewill/ui/tokens'
import { GTR } from '@farewill/ui/tokens'

import ModalComponent from './components/ModalComponent'
import Overlay from './components/Overlay'
import { MODAL_CONTENT } from '@/common/lib/zIndex'
import { MODAL_TRANSITION_DURATION_MS } from './constants'

const StyledModalContainer = styled.div`
  // --modal-gap-x ensures that modal content does not but up against the edge
  // of the viewport.
  --modal-gap-x: ${GTR.XS};
  --modal-gap-y: ${GTR.XXL};
  padding: var(--modal-gap-y) var(--modal-gap-x);

  display: flex;
  min-height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${MODAL_CONTENT};
`

const StyledContentDiv = styled.div`
  border-radius: ${BORDER.RADIUS.M};
  max-width: 100%;
  overflow: hidden;
  height: fit-content;
  width: fit-content;

  ${({ $overlayOnly }) =>
    !$overlayOnly &&
    `
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.5);
    transform: translate(0, -200vh);
    transition: transform ${MODAL_TRANSITION_DURATION_MS}ms;
  `}

  ${({ $show }) =>
    $show &&
    `
    transform: translate(0, 0);
  `}
`

const ModalLayout = ({
  component,
  componentProps,
  closeModal,
  hide,
  keepOpen,
  overlayOnly,
  modalProps,
}) => {
  let content = null
  if (component) {
    content = React.createElement(component, {
      ...componentProps,
      modalProps,
    })
  }
  const show = !!(!hide && component)

  return (
    <>
      <Overlay dismissAction={closeModal} dismissible={!keepOpen} show={show} />
      <StyledModalContainer>
        <ModalComponent show={show}>
          <StyledContentDiv $overlayOnly={overlayOnly} $show={show}>
            {content}
          </StyledContentDiv>
        </ModalComponent>
      </StyledModalContainer>
    </>
  )
}

ModalLayout.propTypes = {
  component: PropTypes.elementType,
  componentProps: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  keepOpen: PropTypes.bool,
  overlayOnly: PropTypes.bool,
  modalProps: PropTypes.shape({
    onClose: PropTypes.func,
    restoreFocusOnClose: PropTypes.bool,
  }),
}

ModalLayout.defaultProps = {
  componentProps: {},
}

export default ModalLayout
