const initialState = {}

export const executorChoices = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_FAREWILL_TRUSTEES_IS_AN_EXECUTOR':
      return { ...state, farewillTrusteesIsAnExecutor: payload }

    case 'SET_FRIENDS_FAMILY_FAREWILL_TRUSTEES_SPLIT_OPTION':
      return { ...state, friendsFamilyFarewillTrusteesSplitOption: payload }

    default:
      return state
  }
}
