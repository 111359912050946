const initialState = null

export const estateSplitIssue = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_ESTATE_SPLIT_ISSUE':
      return payload

    default:
      return state
  }
}
