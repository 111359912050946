import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(
    /* webpackChunkName: "ResetPassword" */ 'ui/routes/reset-password/routes'
  )

const ResetPasswordRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

ResetPasswordRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType).isRequired,
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

ResetPasswordRouteHandler.displayName = 'ResetPasswordRouteHandler'

export default ResetPasswordRouteHandler
