const PATHS = {
  ASSETS_OVERSEAS: 'assets-overseas',
  ASSETS: 'assets',
  BASICS: 'basics',
  BENEFITS: 'benefits',
  BUSINESS_OWNER: 'business-owner',
  BUSINESS_PLAN_OPTIONS: 'business-plan-options',
  BUSINESS_SOLE_OWNER: 'business-sole-owner',
  BUSINESS: 'business',
  CHILDREN: 'children',
  CONGRATULATIONS: 'congratulations',
  FAMILY_CONCERN: 'family-concern',
  INHOUSE_SCOTLAND_NI_CALLBACK: 'inhouse-scotland-ni-callback',
  LOCATION: 'location',
  MEMORY: 'memory',
  ONLINE: 'online',
  PARTNER_DETAILS: 'partner-details',
  PARTNER_INVITED: 'partner-invited',
  PARTNER_SIGN_UP: 'sign-up',
  PARTNER: 'partner',
  PASSWORD: 'password',
  PREFIX: 'start',
  PROPERTY: 'property',
  RELATIONSHIP: 'relationship',
  SCOTLAND_NI: 'scotland-ni',
  TELEPHONE: 'telephone',
  UNSUITABLE: 'unsuitable',
  WAITING_LIST: 'waiting-list',
  WEBSITE_HELP: 'website-help',
}

export const SIGN_UP_START_PATH = `/${PATHS.PREFIX}/${PATHS.RELATIONSHIP}`
export const ONLINE_WILL_SIGN_UP_PATH = `/${PATHS.PREFIX}/${PATHS.ONLINE}`
export const TELEPHONE_WILL_SIGN_UP_PATH = `/${PATHS.PREFIX}/${PATHS.TELEPHONE}`

export default PATHS
