import React from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { splitSdk } from '@splitsoftware/splitio-redux'

import Routes from 'startup/common/routes'
import { useSplitCookieYesConsent } from '../hooks/useSplitCookieYesConsent'

const App = ({ history, store }) => {
  useSplitCookieYesConsent(splitSdk.factory)

  return (
    <Router history={history}>
      <CompatRouter>
        <Routes store={store} />
      </CompatRouter>
    </Router>
  )
}

App.displayName = 'App'

App.propTypes = {
  history: PropTypes.object,
  store: PropTypes.object,
}

export default App
