import { initSplitSdk } from '@splitsoftware/splitio-redux'

import { getClientId } from '../../client/getClientId'
import { Ga4ImpressionListener } from 'lib/split/ga4ImpressionListener'
import { getUserConsent } from 'lib/split/getUserConsent'

// By exporting initSplitSdk returned promise, we can use this wherever we
// need to reference Split data that might not be ready yet. We just need to
// wait for the promise to be fulfilled before accessing the Split data.
// https://help.split.io/hc/en-us/articles/360038851551-Redux-SDK#basic-use
//
// NOTE: This promise resolves when the *main* client is ready(the client for
// our anonymous traffic type). If you need to wait on a client for a different
// traffic type, you'll need to use the `getClient` function from
// common/lib/split/getClient.js
export let initSplitSdkPromise

export const init = () => (dispatch, getState) => {
  const { config } = getState()
  initSplitSdkPromise = dispatch(
    initSplitSdk({
      config: {
        core: {
          authorizationKey: config.SPLIT_CLIENT_KEY,
          // From Split.io SDK documentation:
          // > `key` represents an internal user id, or the account id that
          //   the user belongs to. This could also be a cookie you generate for
          //   anonymous users.
          //
          // Note: Originally we only used an `anonymous` Traffic Type in Split
          // because the experiments we were running tested parts of the flow
          // before signup. So the default `key` is the clientId set in the
          // Cookie which uniquely identifies anonymous traffic. For treatments
          // that have a `user` Traffic Type, we have to set the `key` as the
          // `user._id` in the events we send to Splt to override this default.
          key: getClientId(),
        },
        // When running in Cypress, allow assigned treatments to be overridden
        // by setting the `_MOCKED_FEATURES` global variable in the Cypress
        // test (via the onBeforeLoad callback in cy.visit's options parameter).
        // NOTE: This will only work if Split is started in localhost mode (i.e.
        // the `SPLIT_CLIENT_KEY` env var is 'localhost').
        ...(window.INITIAL_STATE?.config?.RELEASE_ENV === 'test'
          ? { features: window._MOCKED_FEATURES }
          : null),
        // Only provide the `debug` key if ENABLE_SPLIT_DEBUG_MODE is true as
        // the config option here takes precedence over the localStorage flag
        // (which can be useful to enable debug mode in production for your
        // browser).
        ...(config.SPLIT_ENABLE_DEBUG_MODE ? { debug: true } : null),
        scheduler: {
          // Push metrics events every 10 seconds rather than the default 60.
          // Note: The Split SDK also listens for `pagehide` events and flushes
          // metric events to Split servers when these occur so as not to drop
          // events fired just before the user navigates away. However, the
          // SDK does not appear to listen to the beforeunload event
          // (see https://web.dev/bfcache/#never-use-the-unload-event), so an
          // event fired just before the user closes the tab could still be
          // dropped.
          eventsPushRate: 10,
          impressionsRefreshRate: 10,
        },
        userConsent: getUserConsent(),
        impressionListener: new Ga4ImpressionListener(),
      },
    })
  )

  return initSplitSdkPromise
}
