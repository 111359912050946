import { isClient } from 'startup/common/utilities'

export default (store) => (next) => (action) => {
  if (!isClient()) return next(action)

  const showLogs = store.getState().config.SHOW_REDUX_LOGS

  if (showLogs) {
    console.group(action.type)
    console.info('dispatching', action)
  }

  var result = next(action)

  if (showLogs) {
    console.info('next state', store.getState())
    console.groupEnd(action.type)
  }

  return result
}
