export const charities = (state = [], { type, payload }) => {
  switch (type) {
    case 'CHARITY_UPDATE_ID':
      return state.map((charity) => {
        if (charity._id !== payload._id) {
          return charity
        }

        return { ...charity, _id: payload.newId }
      })

    case 'SET_CHARITIES':
      return payload

    default:
      return state
  }
}
