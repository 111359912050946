/* eslint-disable farewill-wills-fe/no-direct-splitio-track-calls */
import { track } from '@splitsoftware/splitio-redux'
import * as Sentry from '@sentry/react'

import { TRAFFIC_TYPES } from './constants'

/**
 * Helper function for tracking events in Split. We should use this instead of
 * calling `track` directly as it includes error handling to catch cases where
 * events are added but not being tracked correctly as a result of a missing
 * `key`.
 *
 * @param {Object} event Split event object
 */
export function trackInSplit(event) {
  const trafficTypeForDefaultClient = TRAFFIC_TYPES.ANONYMOUS
  const eventTrafficType = event.trafficType || trafficTypeForDefaultClient

  if (eventTrafficType !== trafficTypeForDefaultClient && !event.key) {
    const err = new Error(
      `No key provided for event ${event.eventType} in trackInSplit`
    )
    try {
      throw err
    } catch (e) {
      Sentry.captureException(e)
      return false
    }
  }

  return track(event)
}
