import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import OtherLinksSection from './OtherLinksSection'
import MainLinksSection from './MainLinksSection'

const StyledMenu = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  color: ${COLOR.BLACK};
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
`

const MobileMenu = ({ logout, navigationLinks, accountLinks }) => {
  return (
    <StyledMenu>
      {navigationLinks.length > 0 && (
        <>
          <Wrapper container containerPaddingBottom="S" containerPaddingTop="M">
            <MainLinksSection navigationLinks={navigationLinks} />
          </Wrapper>

          <Divider container />
        </>
      )}

      <Wrapper container containerPaddingBottom="M" containerPaddingTop="S">
        <OtherLinksSection accountLinks={accountLinks} logout={logout} />
      </Wrapper>
    </StyledMenu>
  )
}

MobileMenu.propTypes = {
  logout: PropTypes.func,
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  accountLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      external: PropTypes.bool,
    })
  ).isRequired,
}

MobileMenu.defaultProps = {
  logout: null,
}

export default MobileMenu
