import get from 'lodash/get'

import { showAlert } from '@/common/redux/alert/actionCreators'

export default (store) => (next) => (action) => {
  switch (action.type) {
    case 'ADD_EXECUTOR':
      store.dispatch(showAlert('success', 'Executor successfully added.'))
      break
    case 'REMOVE_EXECUTOR':
      store.dispatch(showAlert('success', 'Executor successfully removed.'))
      break
    case 'ERROR_EXECUTOR':
      store.dispatch(showAlert('error', action.error))
      break
    case 'USER_LOADING_ERROR':
      store.dispatch(showAlert('error', action.error))
      break
    case 'USER_UPDATE_SUCCESS':
      store.dispatch(showAlert('success', action.message))
      break
    case 'PET_UPDATE_SUCCESS':
      store.dispatch(showAlert('success', action.message))
      break
    case 'PET_UPDATE_ERROR':
      store.dispatch(showAlert('error', action.error))
      break
    case 'BENEFICIARY_UPDATE_SUCCESS':
      store.dispatch(showAlert('success', action.message))
      break
    case 'BENEFICIARY_UPDATE_ERROR':
      store.dispatch(showAlert('error', action.error))
      break
    case 'INVENTORY_UPDATE_SUCCESS':
      store.dispatch(showAlert('success', action.message))
      break
    case 'INVENTORY_UPDATE_ERROR':
      store.dispatch(showAlert('error', action.error))
      break
    case 'ESTATE_BENEFICIARY_UPDATE_SUCCESS':
      store.dispatch(showAlert('success', action.message))
      break
    case 'ESTATE_BENEFICIARY_UPDATE_ERROR':
      store.dispatch(showAlert('error', action.error))
      break
    case 'REQUEST_ERROR':
      if (action.message) {
        store.dispatch(showAlert('error', action.message))
      } else if (get(action.error, ['response', 'status']) === 401) {
        store.dispatch(showAlert('error', 'Log in to continue'))
      } else {
        store.dispatch(showAlert('error', 'Error connecting to the server'))
      }
      break
    default:
      break
  }

  var result = next(action)

  return result
}
