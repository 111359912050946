import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { hasUserAgreedToCookie } from './cookieYes'
import { getCookieConfig } from './config'

export const getCookie = (name) => Cookies.get(name)

/**
 * Checks if the cookie can be set based on what category the cookie belongs to
 * (e.g. analytics) and sets it if user has granted permission for that category
 * of cookie. If user has not granted permission for this cookie to be set,
 * this function returns early with `false`.
 *
 * @param {string} name Name of the cookie to set
 * @param {string} value Value to set in the cookie
 * @param {Cookies.CookieAttributes} attributes to set on the cookie
 * @returns boolean true if the cookie was set, otherwise false
 */
export const setCookie = (name, value, attributes) => {
  const { CookieNames, cookieYesEnabled } = getCookieConfig()
  if (cookieYesEnabled) {
    if (!Object.values(CookieNames).includes(name)) {
      // Log (but don't throw) the exception to Sentry so we are made aware of the
      // missing cookie configuration but without making the app unusable.
      const error = new Error(`Cookie ${name} does not exist in CookieNames`)
      // Temporary logging to assist with testing
      console.warn(`[Cookies] Cookie ${name} does not exist in CookieNames`)
      Sentry.captureException(error)
    }
    if (!hasUserAgreedToCookie(name)) return false
  }

  Cookies.set(name, value, attributes)
  return true
}

export const getParsedCookie = (cookie) => {
  try {
    return JSON.parse(getCookie(cookie))
  } catch {
    return null
  }
}

export const removeCookie = (cookie) => {
  Cookies.remove(cookie)
}

/**
 * Retrieves experiment flags and corresponding variants from the
 * `fwExperiments` cookie. Values in this cookie are usually set by the
 * farewill.com application when a customer is entered into an experiment.
 *
 * @returns {object} An object containing the experiment flags and the variants
 * for each one that were set in the `fwExperiments` cookie. Flag names
 * converted (and returned in) camelCase.
 */
export const getExperimentsFromCookie = () => {
  const { CookieNames } = getCookieConfig()
  const cookie = getParsedCookie(CookieNames.EXPERIMENTS)
  if (!cookie) {
    return {}
  }

  return Object.entries(cookie).reduce((acc, [key, value]) => {
    const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1)
    return { ...acc, [lowerCaseKey]: value }
  }, {})
}
