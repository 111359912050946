import { setScrollPosition } from 'lib/window'

export default () => (next) => (action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      setScrollPosition(0)
      break
    default:
      break
  }

  return next(action)
}
