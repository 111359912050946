import { useEffect } from 'react'

export const useAutoFocus = (ref) => {
  useEffect(() => {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    const container = ref.current

    if (container) {
      const firstFocusableElement =
        container.querySelectorAll(focusableElements)[0]
      firstFocusableElement.focus()
    }
  }, [ref])
}
