const initialState = {
  visible: false,
}

export const alert = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SHOW_SUCCESS_ALERT':
      return {
        ...state,
        visible: true,
        type: 'success',
        message: payload.message,
      }

    case 'SHOW_ERROR_ALERT':
      return {
        ...state,
        visible: true,
        type: 'error',
        message: payload.message,
        error: payload.error,
        persist: payload.persist,
      }

    case 'HIDE_ALERT':
      return { ...state, visible: false }

    default:
      return state
  }
}
