import { ACCOUNT_PRODUCTS } from 'lib/products/constants'
import { createSelector } from 'reselect'

export const getProducts = (state) => state.account?.products

export const hasOnlineWillOnly = createSelector(
  getProducts,
  (products) =>
    (products?.includes(ACCOUNT_PRODUCTS.ONLINE_WILL) &&
      products?.length === 1) ||
    !products
)
