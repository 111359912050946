import { connect } from 'react-redux'

import { getUserId } from '@/common/redux/user/selectors'

import Footer from './component'

const mapStateToProps = (state) => ({
  marketingSiteUrl: state.config.MARKETING_SITE_URL,
  userId: getUserId(state),
})

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
