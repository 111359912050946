import PropTypes from 'prop-types'

export const UnsavedBeneficiaryType = {
  name: PropTypes.string.isRequired,
  isOverEighteen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  email: PropTypes.string,
  DOB: PropTypes.shape({
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }),
  postal: PropTypes.string,
  guardianIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
}

export const BeneficiaryType = {
  ...UnsavedBeneficiaryType,
  _id: PropTypes.string.isRequired,
}
