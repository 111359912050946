export const initialState = {
  searchTerm: '',
}

export const charitySearch = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CHARITY_SEARCH_SEARCHING':
      return {
        ...state,
        isSearching: action.payload.isSearching,
      }

    case 'UPDATE_CHARITY_SEARCH_RESULTS':
      return {
        ...state,
        results: action.payload.results,
      }

    case 'UPDATE_CHARITY_SEARCH_ERROR':
      return {
        ...state,
        error: action.payload,
      }

    case 'UPDATE_CHARITY_SEARCH_TYPING':
      return {
        ...state,
        isTyping: action.payload.isTyping,
      }

    case 'UPDATE_CHARITY_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
      }

    case 'RESET_CHARITY_SEARCH_TERM':
      return {
        ...state,
        searchTerm: initialState.searchTerm,
      }

    default:
      return state
  }
}
