import PropTypes from 'prop-types'

export const InventoryType = {
  _id: PropTypes.string.isRequired,
  assetType: PropTypes.oneOf([
    'bankAccount',
    'business',
    'lifeInsurance',
    'other',
    'pension',
    'property',
    'stocksAndShares',
  ]),
  details: PropTypes.string.isRequired,
  ownershipType: PropTypes.oneOf(['joint', 'individual', 'dont_know']),
  mortgageStatus: PropTypes.oneOf([
    'with_mortgage',
    'without_mortgage',
    'dont_know',
  ]),
}
