import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { COLOR, INTERACTIVE, GTR } from '@farewill/ui/tokens'

import HeaderHomeLink from '../HeaderHomeLink'
import { BRANDING_LOGOS, DEFAULT_PADDING } from './constants'

const LogoTextSvg = '/images/logos/logo-text.svg'
const LogoSvg = '/images/logos/logo-black.svg'

const StyledCobrandedLockup = styled(Wrapper)`
  display: flex;
  width: auto;

  ${screenMax.s`
    max-width: 240px;
    margin-right: ${GTR.L};
    min-width: var(--main-navigation-lockup-min-width, 120px);
  `}

  svg {
    overflow: visible;

    a {
      &:focus {
        outline: none;
      }

      &:not([disabled]):focus-visible {
        outline: ${INTERACTIVE.OUTLINE_WEIGHT} solid ${COLOR.STATE.ACTIVE};
      }
    }
  }
`

const StyledLogo = styled.img`
  height: 39px;
  color: ${COLOR.BLACK};
`

const Lockup = ({ branding, hideNavigation }) => {
  const BrandLogo = BRANDING_LOGOS[branding] || null
  return BrandLogo ? (
    <StyledCobrandedLockup
      padding={[
        `var(--main-navigation-lockup-top-padding, ${DEFAULT_PADDING})`,
        0,
        `var(--main-navigation-lockup-bottom-padding, ${DEFAULT_PADDING})`,
      ]}
    >
      <BrandLogo />
    </StyledCobrandedLockup>
  ) : (
    <Wrapper padding={[DEFAULT_PADDING, 0]} tag="span">
      <HeaderHomeLink>
        <StyledLogo
          alt="Farewill"
          src={hideNavigation ? LogoTextSvg : LogoSvg}
        />
      </HeaderHomeLink>
    </Wrapper>
  )
}

Lockup.propTypes = {
  branding: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  hideNavigation: PropTypes.bool,
}

Lockup.defaultProps = {
  hideNavigation: false,
}

export default Lockup
