import * as Sentry from '@sentry/react'

import { TRAFFIC_TYPES } from 'lib/split/constants'
import { trackInSplit } from 'lib/split/track'
import { trackInGA4 } from 'lib/tracking'
import { isClient, penceToPounds } from 'startup/common/utilities'
import { getChargeProperties, getVoucherProperties } from './helpers'

const hasUser = (user) => user && user._id

const identifyUser = ({
  user,
  autoAppliedVoucherType,
  autoAppliedVoucherPerc,
}) => {
  if (hasUser(user)) {
    trackInGA4('set', {
      user_id: user._id,
    })
    trackInGA4('set', 'user_properties', {
      utm_channel: user.utmChannel,
      autoapplied_voucher_type: autoAppliedVoucherType,
      autoapplied_voucher_perc: autoAppliedVoucherPerc,
    })
  }
}

const trackUserInSentry = (user) => {
  if (!hasUser(user)) return
  Sentry.setUser({ id: user._id })
}

export default (store) => (next) => async (action) => {
  if (!isClient()) return next(action)

  const state = store.getState()
  const { autoAppliedVoucher, user, doNotTrackUser } = state
  const {
    voucherType: autoAppliedVoucherType,
    voucherPerc: autoAppliedVoucherPerc,
  } = getVoucherProperties(autoAppliedVoucher?.discount)
  const {
    GOOGLE_ADWORDS_ID,
    GOOGLE_ADWORDS_REGISTRATION_EVENT_ID,
    GOOGLE_ADWORDS_PURCHASE_EVENT_ID,
  } = state.config

  switch (action.type) {
    case 'TRACKING_IDENTIFY':
      if (!doNotTrackUser) {
        identifyUser({ user, autoAppliedVoucherType, autoAppliedVoucherPerc })
        trackUserInSentry(user)
      }

      /**
       * Passing user email to `user_data` is required to enable enhanced
       * conversions in Google Ads. The email provided here will be
       * automatically hashed by gtag before reaching Google servers which means
       * that no PII is actually sent to Google (hence we do this even if the
       * user has opted out of tracking).
       * See https://support.google.com/google-ads/answer/12785474?visit_id=638216419000192969-3291842820&rd=1#aec_gtag&zippy=%2Cconfigure-your-conversion-page-google-tag%2Cidentify-and-define-your-enhanced-conversions-fields:~:text=Implement%20the%20enhanced%20conversions%20script
       */
      if (hasUser(user)) {
        trackInGA4('set', 'user_data', {
          email: user.email,
        })
      }
      break

    case 'TRACKING_EVENT':
      if (!state.doNotTrackUser) {
        if (action.trackIn.includes('GA4')) {
          trackInGA4('event', action.event, action.properties)
        }
        if (action.trackIn.includes('SPLIT:USER')) {
          /**
           * Send user Traffic Type events for evaluating treatments that
           * interact with the logged in experience. The `user._id` is the
           * key we can use to uniquely identify users and matches what is
           * passed in to `getTreatments`.
           * We could have events that are relevant to both user and anonymous
           * Traffic Type treatments.
           */
          trackInSplit({
            trafficType: TRAFFIC_TYPES.USER,
            key: user._id,
            eventType: action.event,
            properties: action.properties,
          })
        }
        if (action.trackIn.includes('SPLIT:ANONYMOUS')) {
          /**
           * Send anonymous Traffic Type events for evaluating treatments that
           * interact with the logged out experience. The `clientId` set in the
           * Cookie is the key we can use to uniquely identifiy anonymous
           * traffic and is set when we initialise the Split SDK.
           * We could have events that are relevant to both user and anonymous
           * Traffic Type treatments.
           */
          trackInSplit({
            trafficType: TRAFFIC_TYPES.ANONYMOUS,
            eventType: action.event,
            properties: action.properties,
          })
        }
      }
      break

    case 'TRACKING_RESET':
      if (!state.doNotTrackUser) {
        trackInGA4('set', {
          user_id: null,
        })
        trackInGA4('set', 'user_properties', {
          utm_channel: null,
        })
      }

      break

    case 'TRACKING_REGISTERED': {
      if (!doNotTrackUser) {
        identifyUser({ user })
      }

      const isCouplesRegistration = state.start.willForPartner === true

      trackInGA4('event', 'conversion', {
        send_to: GOOGLE_ADWORDS_ID + '/' + GOOGLE_ADWORDS_REGISTRATION_EVENT_ID,
        transaction_id: user._id,
        value: isCouplesRegistration ? 36 : 28,
        currency: 'GBP',
      })

      if (!doNotTrackUser) {
        trackUserInSentry(user)
        trackInGA4('event', 'sign_up')
        trackInGA4('event', 'prospect', {
          product: 'online_will',
        })

        trackInSplit({
          trafficType: TRAFFIC_TYPES.ANONYMOUS,
          eventType: 'prospect',
          properties: {
            product: 'online_will',
            url: document.location.pathname,
          },
        })

        trackInSplit({
          trafficType: TRAFFIC_TYPES.USER,
          key: user._id,
          eventType: 'registered',
          properties: {
            product: 'online_will',
            url: document.location.pathname,
          },
        })

        window.uetq.push('event', 'sign_up', {})
        window.fbq('track', 'CompleteRegistration')
        window.mintTracker &&
          window.mintTracker('trackEvent', 'lce2', { a2: 'Wills' }, true)
      }

      break
    }

    case 'TRACKING_LEAD': {
      if (!state.doNotTrackUser) {
        window.fbq('track', 'Lead')
      }
      break
    }

    case 'TRACKING_TELEPHONE_WILL_PROSPECT': {
      if (state.doNotTrackUser) break

      trackInGA4('event', 'telephone_will_prospect')
      trackInGA4('event', 'prospect', {
        product: 'telephone_will',
      })
      trackInSplit({
        trafficType: TRAFFIC_TYPES.ANONYMOUS,
        eventType: 'prospect',
        properties: {
          product: 'telephone_will',
          url: document.location.pathname,
        },
      })
      window.mintTracker &&
        window.mintTracker('trackEvent', 'lce2', { a2: 'Wills' }, true)

      break
    }

    case 'TRACKING_VIEW_CONTENT': {
      if (!state.doNotTrackUser) {
        window.fbq('track', 'ViewContent')
      }
      break
    }

    case 'TRACKING_SUBMIT_APPLICATION': {
      if (!state.doNotTrackUser) {
        window.fbq('track', 'SubmitApplication')
      }
      break
    }

    case 'TRACKING_INITIATE_CHECKOUT': {
      const { proposedCharges, voucherCode } = action
      if (!state.doNotTrackUser) {
        window.fbq('track', 'InitiateCheckout')
        trackInGA4('event', 'begin_checkout', {
          ecommerce: {
            currency: 'GBP',
            coupon: voucherCode,
            items: proposedCharges,
          },
        })
        trackInSplit({
          trafficType: TRAFFIC_TYPES.USER,
          eventType: 'checkout_page_view',
          key: user._id,
          properties: {
            product: 'online_will',
            voucher_type: autoAppliedVoucherType,
            voucher_perc: autoAppliedVoucherPerc,
          },
        })
      }
      break
    }

    case 'TRACKING_PURCHASED': {
      const { charges, optedInToSubscription } = action
      const transactionId = charges.map(({ _id }) => _id).join('_')
      const { totalPaidInPounds } = getChargeProperties({
        charges,
        state,
      })

      const eventData = {
        send_to: GOOGLE_ADWORDS_ID + '/' + GOOGLE_ADWORDS_PURCHASE_EVENT_ID,
        value: totalPaidInPounds,
        currency: 'GBP',
        transaction_id: transactionId,
      }
      trackInGA4('event', 'conversion', eventData)

      if (!state.doNotTrackUser) {
        const name = charges.length === 2 ? 'Couple' : 'Single'

        // New GA4 events
        const eventData = {
          currency: 'GBP',
          transaction_id: transactionId,
          value: totalPaidInPounds,
          items: [
            charges.map((charge) => {
              return {
                item_name: name,
                price: penceToPounds(charge.amount),
                quantity: 1,
              }
            }),
          ],
        }

        trackInGA4('event', 'purchase', eventData)

        if (optedInToSubscription) {
          trackInGA4('event', 'subscription-opt-in')
        } else {
          trackInGA4('event', 'subscription-opt-out')
        }

        // Note: We don't track the `purchase` event in Split from the
        // client-side. Instead we send the event from the API directly to
        // Split.

        window.uetq.push('event', 'purchase', {
          revenue: totalPaidInPounds,
        })

        window.fbq('track', 'Purchase', {
          value: totalPaidInPounds,
          currency: 'GBP',
        })

        window.adalyserTracker &&
          window.adalyserTracker('trackEvent', 'lce3', { a4: 'Wills' }, true)
      }
      break
    }

    case 'TRACKING_DONATE_TO_PROMOTED_CHARITY': {
      if (!state.doNotTrackUser) {
        window.fbq('trackCustom', 'DonateToPromotedCharity', {
          charity: action.charity,
        })
      }
      break
    }

    default:
      break
  }

  return next(action)
}
