/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ExitIcon, Button, Divider } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'

const StyledLink = styled(Link)`
  text-decoration: none;
  line-height: 1.6;
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  line-height: 1.6;
`

const StyledLogoutButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: none;
  line-height: 1.6;

  &:hover {
    background-color: initial;
  }

  &:focus {
    outline: 0;
    background-color: initial;
  }

  &:active {
    outline: 0;
    background-color: initial;
  }
`

const AccountLink = ({ link }) => {
  if (link.external) {
    return <StyledExternalLink href={link.url}>{link.name}</StyledExternalLink>
  }
  return (
    <StyledLink
      to={link.url}
      onClick={() => {
        if (link.onClick) link.onClick()
      }}
    >
      {link.name}
    </StyledLink>
  )
}

const OtherLinksSection = ({ logout, accountLinks }) => {
  return (
    <ul>
      {accountLinks.map((link, i) => (
        <li key={i}>
          <AccountLink link={link} />
          {link.dividerBelow && <Divider margin={['S', 0]} />}
        </li>
      ))}
      <li>
        <Divider margin={['S', 0]} />
        <StyledLogoutButton icon={<ExitIcon />} onClick={logout}>
          Save and logout
        </StyledLogoutButton>
      </li>
    </ul>
  )
}

OtherLinksSection.propTypes = {
  logout: PropTypes.func,
  accountLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      external: PropTypes.bool,
      onClick: PropTypes.func,
      dividerBelow: PropTypes.bool,
    })
  ).isRequired,
}

OtherLinksSection.defaultProps = {
  logout: null,
}

export default OtherLinksSection
