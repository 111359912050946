import PropTypes from 'prop-types'

export const UnsavedCharityType = {
  name: PropTypes.string,
  legalName: PropTypes.string,
  number: PropTypes.string,
  source: PropTypes.oneOf(['search', 'promoted', 'manual']),
}

export const CharityType = {
  ...UnsavedCharityType,
  _id: PropTypes.string.isRequired,
  isOrganisation: PropTypes.bool,
  hasExemptCharitableStatus: PropTypes.bool,
  isUserCharity: PropTypes.bool,
}
