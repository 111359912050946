const initialState = {
  hide: true,
  modalProps: {
    restoreFocusOnClose: true,
    onClose: () => undefined,
  },
}

export const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SHOW_MODAL':
      return { ...payload, hide: false }

    case 'HIDE_MODAL':
      return { ...state, ...payload, hide: true }

    case 'RESET_MODAL':
      return initialState

    case 'UPDATE_MODAL_DATA':
      return { ...state, ...payload }

    default:
      return state
  }
}
