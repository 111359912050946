const initialState = false

export const willHasChanged = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_WILL_HAS_CHANGED':
      return payload

    default:
      return state
  }
}
