const initialState = []

export const wills = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_WILLS':
      return payload

    default:
      return state
  }
}
