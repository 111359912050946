export const initialState = {}

export const login = (state = initialState, { type }) => {
  switch (type) {
    case 'RESET_LOGIN':
      return initialState

    default:
      return state
  }
}
