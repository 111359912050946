const parseStringBooleans = (value) => {
  if (typeof value !== 'string') {
    return value
  }

  switch (value) {
    case 'true':
      return true

    case 'false':
      return false

    default:
      return value
  }
}

export const formatQueryObject = (object) =>
  Object.entries(object).reduce(
    (memo, [key, value]) => ({ ...memo, [key]: parseStringBooleans(value) }),
    {}
  )
