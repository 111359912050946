export const toggleAccountMenu = () => {
  return {
    type: 'TOGGLE_ACCOUNT_MENU',
  }
}

export const closeAccountMenu = () => {
  return {
    type: 'CLOSE_ACCOUNT_MENU',
  }
}
