import merge from 'lodash/merge'
import extend from 'lodash/extend'
import { setKeysDeep } from './utilities'

// Main reducer
export default (state = {}, action) => {
  let stateClone

  switch (action.type) {
    case 'CHANGE_STORE_STATE':
      return merge({}, state, action.update)
    case 'SET_STORE_STATE':
      return extend({}, state, action.update)
    case 'UNSET':
      stateClone = merge({}, state)
      setKeysDeep(stateClone, action.keys, null)
      return stateClone
    case 'UNSET_OBJECT':
      stateClone = merge({}, state)
      setKeysDeep(stateClone, action.keys, {})
      return stateClone
    default:
      return state
  }
}
