export const SEARCH_ENGINES = [
  'google',
  'bing',
  'yahoo',
  'ecosia',
  'duckduckgo',
]

export const NONE = '(none)'
export const DIRECT = '(direct)'
export const ORGANIC = 'organic'
export const REFERRAL = 'referral'

export const ignoreUtmSourceList = [NONE, DIRECT, ORGANIC, REFERRAL]
