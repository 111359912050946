import { useEffect } from 'react'

import { getUserConsent } from 'lib/split/getUserConsent'

/**
 * CookieYes fires a `cookieyes_consent_update` event on the document whenever
 * the user updates their consent preferences. When this happens we inform Split
 * about the change in consent so that its consent mode is updated.
 *
 * We pass in the Split factory object to the hook rather than import the
 * splitSdk object within this file to make moving this hook to a standalone
 * NPM module which can be reused across applications easier. If/when we do this
 * the hook will be running in applications that may not use the Redux SDK, so
 * we don't want the hook to have a direct dependency on it.
 *
 * See https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
 */
export const useSplitCookieYesConsent = (splitFactory) => {
  const setConsent = () => {
    const userConsentValue = getUserConsent()
    if (userConsentValue !== 'UNKNOWN') {
      splitFactory.UserConsent.setStatus(userConsentValue === 'GRANTED')
    }
  }

  useEffect(() => {
    document.addEventListener('cookieyes_consent_update', setConsent)
    return () => {
      document.removeEventListener('cookieyes_consent_update', setConsent)
    }
  }, [])
}
