import { setCookie } from 'lib/storage/cookies/helpers'
import { getCookieConfig } from 'lib/storage/cookies/config'
import { getQueryParameters } from 'ui/common/helpers'

/**
 * Stores onboarding data in an `onboarding` cookie. The values stored are
 * retrieved from querystring parameters from the provided url.
 *
 * @param {Object} location Location object from history NPM package
 *  from.
 */
export const setOnboardingCookie = (location) => {
  const { CookieNames } = getCookieConfig()

  const {
    partner_campaign_referral: partnerCampaignReferral,
    telephone_wills_enabled: telephoneWillsEnabled,
    preselected_service: preselectedService,
  } = getQueryParameters(location)

  const cookieData = {
    partnerCampaignReferral,
    telephoneWillsEnabled,
    preselectedService,
  }
  setCookie(CookieNames.ONBOARDING, cookieData)
}
