import React from 'react'
import PropTypes from 'prop-types'
import { P } from '@farewill/ui'

const UnknownError = ({ contactEmail }) => (
  <P>
    There was an issue carrying out that request. Please email us at{' '}
    <a href={`mailto:${contactEmail}`}>{contactEmail}</a> if you require
    assistance.
  </P>
)

UnknownError.propTypes = {
  contactEmail: PropTypes.string.isRequired,
}

export default UnknownError
