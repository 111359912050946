import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(
    /* webpackChunkName: "AboutYourself" */ 'ui/routes/about-yourself/routes'
  )

const AboutYourselfRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

AboutYourselfRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType).isRequired,
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

AboutYourselfRouteHandler.displayName = 'AboutYourselfRouteHandler'

export default AboutYourselfRouteHandler
