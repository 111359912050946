import { getParsedCookie, setCookie } from 'lib/storage/cookies/helpers'

import getTrackingParams from './getTrackingParams'

const setTrackingCookie = ({
  cookieName,
  cookieDomain,
  sessionStorage,
  location,
  referrer,
}) => {
  const cookieSetInSession = sessionStorage?.getItem('trackingCookieSet')

  let trackingParams = getParsedCookie(cookieName)

  const isDirectVisit = !referrer
  const isInternalReferral = referrer?.includes('farewill.com')
  const isCalendlyBookingRedirect = referrer?.includes('calendly.com')
  const overwriteCookie =
    !isDirectVisit && !isInternalReferral && !isCalendlyBookingRedirect

  if (!cookieSetInSession && (!trackingParams || overwriteCookie)) {
    trackingParams = getTrackingParams(location)

    const visibleOnSubdomainsOf = cookieDomain ? { domain: cookieDomain } : null

    const cookieSet = setCookie(cookieName, trackingParams, {
      expires: 90,
      ...visibleOnSubdomainsOf,
    })
    if (cookieSet) {
      sessionStorage?.setItem('trackingCookieSet', 'true')
    }
  }
}

export default setTrackingCookie
