import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import {
  getTreatments,
  selectTreatmentWithConfigAndStatus,
} from '@splitsoftware/splitio-redux'

const memoizedSelectTreatmentWithConfigAndStatus = createSelector(
  (state) => state.splitio,
  (_, flagName, key) => ({ flagName, key }),
  (splitio, { flagName, key }) =>
    selectTreatmentWithConfigAndStatus(splitio, flagName, key)
)

export const useSplitTreatment = (flagName, key) => {
  const dispatch = useDispatch()

  const {
    isReady,
    isTimedout,
    treatment: treatmentConfig,
  } = useSelector((state) =>
    memoizedSelectTreatmentWithConfigAndStatus(state, flagName, key)
  )

  const { config, treatment } = treatmentConfig

  useEffect(() => {
    dispatch(getTreatments({ key: key, splitNames: [flagName] }))
  }, [dispatch, key, flagName])

  return { isReady, isTimedout, treatment, config }
}
