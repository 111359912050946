export const toggleMobileNav = () => {
  return {
    type: 'TOGGLE_MOBILE_NAV',
  }
}

export const closeMobileNav = () => {
  return {
    type: 'CLOSE_MOBILE_NAV',
  }
}
