import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import PATHS from './paths'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(/* webpackChunkName: "Start" */ 'ui/routes/start/routes')

const PATHS_WITH_DECORATIVE_BG = [
  PATHS.BASICS,
  PATHS.CONGRATULATIONS,
  PATHS.PARTNER_DETAILS,
  PATHS.PARTNER_INVITED,
  PATHS.PASSWORD,
]

const StartRouteHandler = () => {
  const params = useParams()
  const useDecorativeBackground = PATHS_WITH_DECORATIVE_BG.includes(params.page)
  return (
    <RouteHandler
      componentProps={{ key: params.page, useDecorativeBackground }}
      getComponent={getComponent}
      layoutConfig={{
        isPublic: true,
        noIndex: true,
        hideFooter: true,
        hideNavigation: true,
        hideBorder: true,
        centerContentVertically: true,
        useDecorativeBackground,
      }}
    />
  )
}

StartRouteHandler.displayName = 'StartRouteHandler'

export default StartRouteHandler
