import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FONT } from '@farewill/ui/tokens'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  text-decoration: none;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;
  }
`

const MainLinksSection = ({ navigationLinks }) => (
  <ul>
    {navigationLinks.map((link, i) => (
      <li key={i}>
        <StyledLink to={link.url}>{link.name}</StyledLink>
      </li>
    ))}
  </ul>
)

MainLinksSection.propTypes = {
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default MainLinksSection
