import { penceToPounds } from 'startup/common/utilities'
import {
  getCouplesWillPriceInPounds,
  getSingleWillPriceInPounds,
} from '@/common/redux/config/selectors'

export const getChargeProperties = ({ charges, state }) => {
  const totalPaidInPounds = penceToPounds(
    charges.reduce((total, charge) => total + charge.amount, 0)
  )
  const discount =
    charges.length === 2
      ? 1 - totalPaidInPounds / getCouplesWillPriceInPounds(state)
      : 1 - totalPaidInPounds / getSingleWillPriceInPounds(state)

  return { discount, totalPaidInPounds }
}

export const getVoucherProperties = (discount) => {
  let voucherType
  switch (discount) {
    case 1:
      voucherType = 'free'
      break
    case 0:
    case undefined:
      voucherType = 'none'
      break
    default:
      voucherType = 'discounted'
  }

  return {
    voucherType,
    voucherPerc: discount ? discount * 100 : 0,
  }
}
