/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

import { LayoutConfigType, RoutePropsType } from 'types'
import MainLayout from 'ui/layouts/MainLayout'
import Fallback from './components/Fallback'

/**
 * [1] We need to continue passing routeProps down until we've migrated all
 *     route trees entirely to React Router's V6 APIs. Once that is done we can
 *     remove routeProps from this component.
 */
const RouteHandler = React.memo(
  ({ layoutConfig, routeProps, getComponent, componentProps }) => {
    const Component = useMemo(() => loadable(getComponent), [getComponent])
    return (
      <MainLayout config={layoutConfig}>
        <Component
          fallback={<Fallback />}
          {...routeProps} // [1]
          {...componentProps}
        />
      </MainLayout>
    )
  }
)

RouteHandler.propTypes = {
  componentProps: PropTypes.object,
  getComponent: PropTypes.func.isRequired,
  layoutConfig: PropTypes.shape(LayoutConfigType),
  routeProps: PropTypes.shape(RoutePropsType),
}

RouteHandler.displayName = 'RouteHandler'

export default RouteHandler
