import { connect } from 'react-redux'

import history from 'startup/common/history'
import { trackEvent } from '@/common/redux/tracking/actionCreators'
import { loginPromise } from 'startup/common/actionCreators'
import { isLoggedIn } from '@/common/redux/account/selectors'
import { isLoggedInWillsUser } from '@/common/redux/user/selectors'
import { SIGN_UP_START_PATH } from 'ui/routes/start/paths'

import LogIn from './component'

const homeLinkTo = (singleSignOnInitiated) =>
  singleSignOnInitiated ? '/sso/zendesk' : '/wills'

const mapStateToProps = (state) => ({
  marketingSiteUrl: state.config.MARKETING_SITE_URL,
})

const redirectUrl = (singleSignOnInitiated) => {
  const currentLocation = window.location
  if (singleSignOnInitiated) {
    return '/sso/zendesk'
  } else if (currentLocation.pathname.includes('/log-in')) {
    return '/wills'
  } else {
    return currentLocation.href
  }
}

const mapDispatchToProps = {
  redirectLoggedInUsers: () => (dispatch, getState) => {
    const state = getState()

    if (isLoggedInWillsUser(state)) {
      history.push(homeLinkTo(false))
    } else if (isLoggedIn(state)) {
      // If the logged in user does not yet have a will, we send them
      // to the start of the screener flow.
      history.replace(SIGN_UP_START_PATH)
    }
  },

  handleSubmit:
    ({ email, password }) =>
    (dispatch, getState) => {
      const { singleSignOn } = getState()

      return dispatch(loginPromise({ email, password })).then(() => {
        // For Zendesk chat to work, the user needs to be logged in on page load
        dispatch(trackEvent('login'))
        const redirectTo = redirectUrl(singleSignOn.initiated)

        // [1] Perform any hard reload/navigation in a new task (see [a]) so
        //     that Redux actions have a chance to execute (required for our
        //     tracking events to fire, since we implement those via Redux).
        // [a] https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/)
        if (redirectTo === window.location.href) {
          setTimeout(() => {
            // [1] (See above)

            // If the user is on a URL that contains a fragment, the browser will
            // not trigger an actual navigation if the underlying path is the
            // same so we force a reload via location.reload() instead. See
            // https://linear.app/farewill/issue/WILLA-378/login-doesnt-appear-to-work-properly-when-loading-site-via-email-links
            window.location.reload()
          }, 0)
        } else {
          setTimeout(() => {
            // [1] (See above)
            window.location.href = redirectTo
          })
        }
      })
    },

  trackClickGa4: (event, props) => (dispatch) => {
    dispatch(trackEvent(event, props))
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(LogIn)
