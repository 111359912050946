import { ONBOARDING_FLAG_KEY } from './constants'
import history from 'startup/common/history'
import { getParsedCookie } from '../common/lib/storage/cookies/helpers'
import { setOnboardingCookie } from './setOnboardingCookie'
import { setStartState } from '@/common/redux/start/actionCreators'
import { getCookieConfig } from '../common/lib/storage/cookies/config'

export const configureOnboarding = (store) => {
  const { CookieNames } = getCookieConfig()

  try {
    const cookieSetInSession = sessionStorage.getItem(ONBOARDING_FLAG_KEY)
    if (!cookieSetInSession) {
      setOnboardingCookie(history.location)
      sessionStorage.setItem(ONBOARDING_FLAG_KEY, 'true')
    }

    const onboardingParameters = getParsedCookie(CookieNames.ONBOARDING)
    if (onboardingParameters) {
      store.dispatch(setStartState(onboardingParameters))
    }
  } catch {
    return null
  }
}
