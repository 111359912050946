export const showMobileNav = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_MOBILE_NAV': {
      return !state
    }
    case 'CLOSE_MOBILE_NAV': {
      return false
    }
    default:
      return state
  }
}
