const initialState = {}

export const start = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_BASICS':
      return {
        ...state,
        preferredName: payload.preferredName,
        email: payload.email,
        marketingEmailStatus: payload.marketingEmailStatus,
      }

    case 'SET_START_STATE':
    case 'SET_ONBOARDING_FORM_POSITION':
      return { ...state, ...payload }

    case 'SET_ONBOARDING_FORM_ANSWER':
      return {
        ...state,
        answers: { ...state.answers, [payload.questionId]: payload.value },
        outcome: payload.outcome,
      }

    default:
      return state
  }
}
