export const registered = () => {
  return {
    type: 'TRACKING_REGISTERED',
  }
}

export const lead = () => {
  return {
    type: 'TRACKING_LEAD',
  }
}

export const teleWillProspect = () => {
  return {
    type: 'TRACKING_TELEPHONE_WILL_PROSPECT',
  }
}

export const viewContent = () => {
  return {
    type: 'TRACKING_VIEW_CONTENT',
  }
}

export const submitApplication = () => {
  return {
    type: 'TRACKING_SUBMIT_APPLICATION',
  }
}

export const initiateCheckout = (proposedCharges, voucherCode) => {
  return {
    type: 'TRACKING_INITIATE_CHECKOUT',
    proposedCharges,
    voucherCode,
  }
}

export const purchased = (charges, optedInToSubscription) => {
  return {
    type: 'TRACKING_PURCHASED',
    charges,
    optedInToSubscription,
  }
}

export const trackingIdentify = (user) => {
  return {
    type: 'TRACKING_IDENTIFY',
    user,
  }
}

export const trackingReset = () => {
  return {
    type: 'TRACKING_RESET',
  }
}

export const trackEvent = (event, properties, trackIn) => {
  return {
    type: 'TRACKING_EVENT',
    event,
    properties,
    trackIn: trackIn || ['GA4'],
  }
}

export const trackingDonateToPromotedCharity = (charity) => {
  return {
    type: 'TRACKING_DONATE_TO_PROMOTED_CHARITY',
    charity,
  }
}
