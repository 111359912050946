let CookieNames
let CookieCategories
let cookieYesEnabled

export const configureCookies = (config) => {
  const {
    FIRST_TOUCH_COOKIE_NAME,
    REFERRAL_COOKIE_NAME,
    TRACKING_COOKIE_NAME,
  } = config

  CookieNames = {
    CLIENT_ID: 'fwClientId',
    EXPERIMENTS: 'fwExperiments',
    HIDE_COOKIE_BANNER: 'cookieBannerHidden',
    FIRST_TOUCH_TRACKING: FIRST_TOUCH_COOKIE_NAME,
    LAST_TOUCH_TRACKING: TRACKING_COOKIE_NAME,
    ONBOARDING: 'onboarding',
    WILLS_REFERRAL_PARAMS: REFERRAL_COOKIE_NAME,
  }

  /**
   * [1] See https://www.notion.so/farewill/Generating-storing-an-anonymous-identifier-for-visitors-to-com-804b4c49744942f6a46cbd3e17eb6b79?pvs=4
   * [2] We don't actually use a separate category for performance cookies as we
   *     group them with analytics cookies. As a result we have configured
   *     CookieYes to hide this category from the Cookie dialog.
   *     See https://www.notion.so/farewill/Cookies-1cab4b51d61e4999b9fa5cb06fd7545d?pvs=4#224731af6bd243ef897026b64eb8e7ad
   */
  CookieCategories = {
    necessary: [
      CookieNames.CLIENT_ID, // [1]
      CookieNames.HIDE_COOKIE_BANNER,
      CookieNames.ONBOARDING,
      CookieNames.WILLS_REFERRAL_PARAMS,
    ],
    functional: [],
    analytics: [
      CookieNames.EXPERIMENTS,
      CookieNames.FIRST_TOUCH_TRACKING,
      CookieNames.LAST_TOUCH_TRACKING,
    ],
    performance: [], // [2]
    advertisement: [],
  }

  cookieYesEnabled = config.FF_COOKIE_BANNER_ENABLED
}

export const getCookieConfig = () => {
  return {
    CookieNames,
    CookieCategories,
    cookieYesEnabled,
  }
}
