import { connect } from 'react-redux'
import get from 'lodash/get'

import MarketingSiteLink from './component'

const mapStateToProps = (state) => ({
  baseUrl: get(state, ['config', 'MARKETING_SITE_URL']),
})

export default connect(mapStateToProps)(MarketingSiteLink)
