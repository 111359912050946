import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(
    /* webpackChunkName: "PrintAndSend" */ 'ui/routes/print-and-send/routes'
  )

const PrintAndSendRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

PrintAndSendRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType),
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

PrintAndSendRouteHandler.displayName = 'PrintAndSendRouteHandler'

export default PrintAndSendRouteHandler
