import PropTypes from 'prop-types'

export const UnsavedChurchType = {
  acnyId: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  diocese: PropTypes.string,
}

export const UnsavedSelectedChurchType = {
  ...UnsavedChurchType,
  manuallyEntered: PropTypes.bool.isRequired,
}

export const ChurchType = {
  _id: PropTypes.string.isRequired,
  acnyId: PropTypes.number,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string,
  postcode: PropTypes.string.isRequired,
  diocese: PropTypes.string.isRequired,
  manuallyEntered: PropTypes.bool,
}

export const ChurchBeneficiaryType = {
  _id: PropTypes.string.isRequired,
  acnyId: PropTypes.number,
  name: PropTypes.string.isRequired,
  postal: PropTypes.string.isRequired,
  diocese: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}
