import { changeUserFuneralRestingPlace } from '@/common/redux/user/actionCreators'

export const updateFuneralWishes = (funeralWishes) => ({
  type: 'UPDATE_FUNERAL_WISHES',
  funeralWishes,
})

export const unsetFuneralWishes = (fields) => ({
  type: 'UNSET_FUNERAL_WISHES',
  fields,
})

export const removeFuneralWishes = () => ({
  type: 'REMOVE_FUNERAL_WISHES',
})

export const updateRestingPlace =
  (selectedRestingPlace) => (dispatch, getState) => {
    const {
      user: { funeralRestingPlace },
    } = getState()

    const userHasDeselected = funeralRestingPlace === selectedRestingPlace
    const newRestingPlace = userHasDeselected ? null : selectedRestingPlace

    return dispatch(changeUserFuneralRestingPlace(newRestingPlace))
  }
