import { BRANCHES } from 'ui/routes/start/constants'

export const setStartState = (object) => ({
  type: 'SET_START_STATE',
  payload: object,
})

export const setBasics = ({ preferredName, email, marketingEmailStatus }) => ({
  type: 'SET_BASICS',
  payload: { preferredName, email, marketingEmailStatus },
})

export const setOnboardingFormPosition = (questionId) => {
  // eslint-disable-next-line no-unused-vars
  const [branchId] = Object.entries(BRANCHES).find(([id, branch]) =>
    branch.questions.includes(questionId)
  )

  return {
    type: 'SET_ONBOARDING_FORM_POSITION',
    payload: { branchId, questionId },
  }
}

export const setOnboardingFormAnswer = ({ questionId, value }) => ({
  type: 'SET_ONBOARDING_FORM_ANSWER',
  payload: { questionId, value },
})
