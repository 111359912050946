import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(/* webpackChunkName: "Account" */ 'ui/routes/account/routes')

const AccountRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

AccountRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType),
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

AccountRouteHandler.displayName = 'AccountRouteHandler'

export default AccountRouteHandler
