import { connect } from 'react-redux'

import { closeModal } from '@/common/redux/modal/actionCreators'

import ConfirmModal from './component'

const mapStateToProps = (state) => ({
  confirmCallback: state.modal.confirmCallback,
  removeCallback: state.modal.removeCallback,
  content: state.modal.content,
  confirmText: state.modal.confirmText,
  title: state.modal.title,
})

const mapDispatchToProps = (dispatch) => ({
  confirmHandler: (confirmCallback, removeCallback) => {
    confirmCallback()
    removeCallback && removeCallback()
    dispatch(closeModal())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)
