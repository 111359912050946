let id = 0

export const requestsPending = (state = [], action) => {
  const stateClone = state.slice()
  let index = -1

  switch (action.type) {
    case 'REQUEST_PENDING':
      return state.concat({ name: action.name, id: id++ })
    case 'REQUEST_COMPLETED':
      index = state.findIndex(({ name }) => name === action.name)
      stateClone.splice(index, 1)
      return stateClone
    case 'REQUEST_ERROR':
      index = state.findIndex(({ name }) => name === action.name)
      stateClone.splice(index, 1)
      return stateClone
    default:
      return state
  }
}
