import omit from 'lodash/omit'

export const funeralWishes = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_FUNERAL_WISHES':
      return {
        ...state,
        ...action.funeralWishes,
      }
    case 'UNSET_FUNERAL_WISHES':
      return omit(state, action.fields)
    case 'REMOVE_FUNERAL_WISHES':
      return {}

    default:
      return state
  }
}
