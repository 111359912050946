import isUndefined from 'lodash/isUndefined'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import isObject from 'lodash/isObject'
import isDate from 'lodash/isDate'
import partial from 'lodash/partial'
import padStart from 'lodash/padStart'
import isNumber from 'lodash/isNumber'
import lowerCase from 'lodash/lowerCase'

import moment from 'moment'

/**
 * Manipulate object and update data within
 *
 * @param {object} obj  The object you'd like to clone
 * @param {array} keys The keys within the object
 * @param {array} value The values for these keys
 */
export const deepObjectCreate = function (obj, keys, value) {
  if (!keys || !keys.length) throw new Error('No keys array supplied:', keys)
  let objClone = cloneDeep(obj)
  let acc = objClone
  keys.forEach(function (key, index) {
    acc[key] = index + 1 === keys.length ? value : {}
    acc = acc[key]
  })
  return objClone
}

export const setKeysDeep = function (obj, keys, value) {
  if (!keys.length) return obj
  if (keys.length === 1) {
    obj[keys[0]] = value
    return obj
  } else {
    const keysClone = keys.slice()
    const nextKey = keysClone.shift()
    const next = obj[nextKey]
    obj[nextKey] = setKeysDeep(isObject(next) ? next : {}, keysClone, value)
    return obj
  }
}

/**
 * Parse a string and convert it into a boolean
 *
 * @param {string|boolean} string Either a boolean or string
 */
export const parseBool = function (string) {
  return string === 'true' || string === true
}

/**
 * Build up a more human readable list from an array.
 *
 * @param {string} separator The character or string the words should be separated by
 * @param {string} finalSeparator The character or string the last word should be separated by
 * @param {array} array The array of words which you wish to separate
 */
export const listWords = function (separator, finalSeparator, array) {
  if (!separator) {
    separator = ' and '
  }
  if (array.length <= 1) {
    return array[0]
  }
  return array
    .map(function (el, index) {
      if (finalSeparator && index === array.length - 2) {
        return el + finalSeparator
      }
      return index === array.length - 1 ? el : el + separator
    })
    .reduce(function (prev, next) {
      return prev + next
    })
}

export const standardList = partial(listWords, ', ', ' and ')

/**
 * Check if a object has a key with the value of
 *
 * @param {string} key The key you're checking
 * @param {mixed} value What value it should be equal to
 */
export const keyHasValue = (key, value) => (obj) =>
  (key ? obj[key] : obj) === value

export const dateTransform = function (mode, keys) {
  if (!isArray(keys)) keys = [keys]
  return (data) => {
    const clone = merge({}, data)
    keys.forEach((key) => {
      const el = data[key]
      if (!el) return
      if (mode !== 'to_object') {
        clone[key].month = parseInt(clone[key].month, 10) - 1
        clone[key] = moment(clone[key]).toDate()
      } else {
        if (!isDate(el)) return
        clone[key] = {
          day: padStart(el.getDate(), 2, '0'),
          month: padStart(el.getMonth() + 1, 2, '0'),
          year: padStart(el.getFullYear(), 2, '0'),
        }
      }
    })
    return clone
  }
}

export const sumArray = function (key, estateBeneficiaries) {
  // * 1e12 to prevent fraction additions because javascript.
  return (
    estateBeneficiaries.reduce(
      (acc, next) => acc + Math.round(parseFloat(next[key]) * 1e12),
      0
    ) / 1e12
  )
}

/**
 * Convert pence to pounds
 *
 * @param {integer} value Pence value
 * @param {integer} fixedPoints The amount of points past the decimal to show
 */
export const penceToPounds = function (value, fixedPoints) {
  if (!isNumber(value)) {
    throw new Error('Value must be a number.')
  }

  const poundsValue = value / 100
  const roundedPoundsvalue = Math.floor(poundsValue)

  if (isUndefined(fixedPoints) || poundsValue !== roundedPoundsvalue) {
    fixedPoints = 2
  }

  return poundsValue.toFixed(fixedPoints)
}

/**
 * Round down a number to the nearest 100
 *
 * @param {(integer|string)} value value
 */
export const roundDownToNearestHundred = (value) => {
  const rounded = Math.floor(parseInt(value, 10) / 100) * 100
  return rounded.toLocaleString('en')
}

export const decamelCase = function (string) {
  return lowerCase(string.replace(/([A-Z])/g, ' $1'))
}

/**
 * take the path and remove the '/'
 *
 * @param {string} pathName The path you're on
 */
export const pathNameFormatter = (pathName) => {
  if (pathName.charAt(0) === '/') {
    pathName = pathName.substr(1)
  }

  if (pathName === '') {
    return 'landing'
  }

  return pathName
}

export const isClient = () => {
  try {
    window // eslint-disable-line no-unused-expressions
    return true
  } catch (e) {
    return false
  }
}
