import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import isFunction from 'lodash/isFunction'

import { Wrapper } from '@farewill/ui'

import {
  API_ERROR_KEY,
  UNKNOWN_ERROR_TEXT,
} from 'ui/common/FormStatus/constants'

/**
 * As we implement FormikForm across more forms, we can inspect the
 * errors thrown by the API, and if we want to catch them here we can
 * update this function to handle different error formats.
 */
const handleError = ({ error, formik }) => {
  const text = error?.reason

  if (text) {
    formik.setFieldError(API_ERROR_KEY, text)
  } else {
    formik.setFieldError(API_ERROR_KEY, UNKNOWN_ERROR_TEXT)
  }
}

const FormikForm = ({
  catchApiError,
  children,
  initialValues,
  onSubmit,
  validateOnBlur = false,
  validateOnChange = false,
  validationSchema,
}) => {
  return (
    <Formik
      // Setting enableReinitialize to true ensures that if the
      // initialValues object is updated, the values object is also
      // updated.
      enableReinitialize
      initialValues={initialValues}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      validationSchema={validationSchema}
      onSubmit={(values, formik) =>
        onSubmit(values, formik).catch((error) => {
          if (catchApiError) return handleError({ error, formik })
          throw error
        })
      }
    >
      {(formik) => (
        // If we want to run the Yup validation we need to disable the
        // HTML form validation (e.g. the `required` attribute) - as
        // this prevents the form from being submitted.
        <Wrapper noValidate={!!validationSchema} tag={Form}>
          {isFunction(children) ? children(formik) : children}
        </Wrapper>
      )}
    </Formik>
  )
}

FormikForm.propTypes = {
  catchApiError: PropTypes.bool,
  children: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validationSchema: PropTypes.object,
}

FormikForm.defaultProps = {
  catchApiError: false,
}

export default FormikForm
