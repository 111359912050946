import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, H, P, Button } from '@farewill/ui'

import Modal from 'ui/common/modal'

const PolicyUpdatedModal = ({
  termsUpdated,
  privacyUpdated,
  onAccept,
  marketingSiteUrl,
}) => (
  <Modal hideHeader title="We've updated our policies">
    <H size="S">A couple of updates...</H>
    <P>
      Since you last visited Farewill we have updated our{' '}
      {termsUpdated && (
        <a
          href={`${marketingSiteUrl}/terms`}
          rel="noopener noreferrer"
          target="_blank"
        >
          terms of service
        </a>
      )}
      {termsUpdated && privacyUpdated && ' and '}
      {privacyUpdated && (
        <a
          href={`${marketingSiteUrl}/privacy`}
          rel="noopener noreferrer"
          target="_blank"
        >
          privacy policy
        </a>
      )}
      . If you’re happy to, we’ll need you to accept below.
    </P>

    <Wrapper margin={['M', 0, 0]}>
      <Button.Primary
        stretch
        onClick={() => onAccept({ privacyUpdated, termsUpdated })}
      >
        Accept and Continue
      </Button.Primary>
    </Wrapper>
  </Modal>
)

PolicyUpdatedModal.propTypes = {
  termsUpdated: PropTypes.bool.isRequired,
  privacyUpdated: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  marketingSiteUrl: PropTypes.string.isRequired,
}

export default PolicyUpdatedModal
