import { v4 as uuid } from 'uuid'
import { getCookieConfig } from 'lib/storage/cookies/config'
import { getCookie, setCookie } from 'lib/storage/cookies/helpers'

/**
 * Fetches a (hopefully) unique id which can be used to identify this users
 * browser/browsing session. This is passed to the Split.io SDK so that the user
 * is served consistent treatments for any feature flags, meaning they won't see
 * different treatments across page loads/browsing sessions where browser
 * storage is retained.
 *
 * This code is currently duplicated in the .com repo, so in the majority of
 * cases the clientId will have been set by the equivalent code in .com, and
 * will be read of of the cookie in the Wills FE.
 *
 * Ultimately, we should extract this common code to a standalone NPM module,
 * along with the Cookie & CookieYes helpers as they are also duplicated across
 * repositories at the moment.
 *
 * @returns A UUID string, or null if consent to store the ClientID cooke has
 *          not been granted.
 */
export const getClientId = () => {
  const { CookieNames } = getCookieConfig()
  const existingKey = getCookie(CookieNames.CLIENT_ID)
  if (existingKey) {
    return existingKey
  }

  const clientId = uuid()
  setCookie(CookieNames.CLIENT_ID, clientId, {
    domain: window.INITIAL_STATE.config.FAREWILL_COOKIE_DOMAIN,
    expires: 90,
  })
  return clientId
}
