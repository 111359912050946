import PropTypes from 'prop-types'

export const PetType = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  guardianIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
}

export const UnsavedPetType = {
  name: PropTypes.string,
  guardianIds: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string,
  firstName: PropTypes.string,
}
