import PropTypes from 'prop-types'

export const SubscriptionType = {
  _id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['trialing', 'active', 'past_due', 'canceled'])
    .isRequired,
  currentPeriodEnd: PropTypes.number.isRequired,
  cancelAtPeriodEnd: PropTypes.bool.isRequired,
  subscriptionId: PropTypes.string.isRequired,
}

export const UnclaimedSubscriptionType = {
  ...SubscriptionType,
  userId: PropTypes.oneOf([null]),
}
