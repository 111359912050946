const initialState = {
  answers: [],
}

export const bespokeCapacityChecks = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case 'SET_BESPOKE_CAPACITY_CHECK_ANSWER':
      return {
        ...state,
        answers: state.answers
          .filter((answer) => answer.type !== payload.type)
          .concat(payload),
      }

    case 'SET_CONTESTATION_RISK_ACKNOWLEDGEMENT':
      return {
        ...state,
        hasAcknowledgedContestationRisk: payload,
      }

    default:
      return state
  }
}
