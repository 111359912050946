import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Image, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

const StyledDecorativeWrapper = styled(Wrapper)`
  --decorative-background-height: 300px;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
  }
  &::before {
    background: ${COLOR.ACCENT.PRIMARY};
    height: var(--decorative-background-height);
    top: 0;
  }
  &::after {
    aspect-ratio: 2500 / 241;
    background: url('https://res.cloudinary.com/dwr27vxv7/image/upload/v1571915286/textures/edge-1.png')
      no-repeat center bottom;
    background-size: cover;
    top: var(--decorative-background-height);
  }
`

const StyledDecorativeImageContainer = styled(Wrapper)`
  height: var(--decorative-background-height);
  left: 0;
  overflow: visible;
  position: absolute;
  top: 0;
`

const decorativeImageStyles = css`
  position: absolute;
  user-select: none;
  z-index: 2;
`

const StyledDotsImage = styled(Image)`
  ${decorativeImageStyles}
  opacity: 0.5;
  transform: translate(-27%, -15%);
  width: clamp(170px, 50vw, 293px);
`

const StyledSwoopImage = styled(Image)`
  ${decorativeImageStyles}
  bottom: 5px;
  right: 0;

  transform: translate(30%, 5%);
  width: clamp(180px, 50vw, 280px);
`

const DecorativeBackground = ({ className }) => {
  return (
    <StyledDecorativeWrapper className={className}>
      <StyledDecorativeImageContainer>
        <StyledDotsImage
          draggable="false"
          path={'textures/dots-cream'}
          width={293}
        />
        <StyledSwoopImage
          draggable="false"
          formatPath={({ path, ext }) =>
            `${CLOUDINARY_ROOT_PATH}/e_trim/c_scale,f_auto,q_auto,a_180/${path}.${ext}`
          }
          path={'textures/sketchy-swoop-yellow-02_ha58tm'}
          width={500}
        />
      </StyledDecorativeImageContainer>
    </StyledDecorativeWrapper>
  )
}

DecorativeBackground.propTypes = { className: PropTypes.string }

export default DecorativeBackground
