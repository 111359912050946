import { getCookieConfig } from 'lib/storage/cookies/config'
import { getParsedCookie } from 'lib/storage/cookies/helpers'
import { NONE } from 'lib/tracking/constants'

export const storeVoucherCode = (store) => {
  const { CookieNames } = getCookieConfig()
  const { query } = store.getState()
  const { voucher } = getParsedCookie(CookieNames.WILLS_REFERRAL_PARAMS) || {}
  const voucherCode = voucher && voucher !== NONE ? voucher : query.code
  if (localStorage && voucherCode) {
    localStorage.setItem('autoAppliedVoucherCode', voucherCode)
  }
}
