import { createSelector } from 'reselect'
import { identity, isEmpty } from 'lodash'
import pick from 'lodash/pick'

import { SERVICE_OPTIONS } from 'lib/products/constants'
import {
  QUESTIONS,
  BRANCHES,
  QUESTION_ID,
  BRANCH_ID,
} from 'ui/routes/start/constants'
import PATHS from 'ui/routes/start/paths'

export const getOnboardingState = (state) => state.start || {}

export const getOnboardingAnswers = createSelector(
  getOnboardingState,
  ({ answers }) => answers || {}
)

export const isInRelationship = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.RELATIONSHIP]
)

export const isParent = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.CHILDREN]
)

export const isPropertyOwner = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.PROPERTY]
)

export const isDomiciledInEnglandOrWales = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.ENGLAND_OR_WALES_DOMICILE]
)

export const isDomiciledInScotlandOrNI = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.SCOTLAND_OR_NI_DOMICILE]
)

export const hasForeignAssets = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.FOREIGN_ASSETS]
)

export const isBusinessOwner = createSelector(
  getOnboardingAnswers,
  (answers) => !!answers[QUESTION_ID.BUSINESS]
)

export const hasCapacityIssue = createSelector(
  getOnboardingAnswers,
  (answers) => {
    const capacityQuestionIds = BRANCHES[BRANCH_ID.CAPACITY].questions
    const capacityAnswers = pick(answers, capacityQuestionIds)

    return Object.values(capacityAnswers).some((answer) => !!answer)
  }
)

export const getPreselectedService = createSelector(
  getOnboardingState,
  ({ preselectedService }) =>
    Object.values(SERVICE_OPTIONS).find(
      (service) => service.queryParam === preselectedService
    )
)

export const isCampaignPageVisitor = createSelector(
  getOnboardingState,
  ({ partnerCampaignReferral }) => !!partnerCampaignReferral
)

export const isEligibleForTelephoneWills = createSelector(
  [isCampaignPageVisitor, getOnboardingState],
  (isCampaignPageVisitor, { telephoneWillsEnabled }) => {
    if (!isCampaignPageVisitor) {
      return true
    }

    return !!telephoneWillsEnabled
  }
)

export const getBranch = createSelector(
  getOnboardingState,
  ({ branchId }) => BRANCHES[branchId]
)

export const getQuestionIndex = createSelector(
  [getBranch, getOnboardingState],
  (branch, { questionId }) => {
    if (!branch) return null

    return branch.questions.findIndex((question) => question === questionId)
  }
)

const getFirstEligibleQuestion = (questionIds) => (state) =>
  questionIds
    .map((questionId) => QUESTIONS[questionId])
    .find((question) => {
      if (!question.condition) {
        return true
      }

      return question.condition(state)
    })

export const getNextQuestion = createSelector(
  [getBranch, getOnboardingState, getQuestionIndex, identity],
  (branch, { branchId }, index, state) => {
    if (!branch) return null

    const remainingQuestionIds = branch.questions.slice(index + 1)
    let nextQuestion = getFirstEligibleQuestion(remainingQuestionIds)(state)

    if (branchId !== BRANCH_ID.CAPACITY && !nextQuestion) {
      const capacityBranchQuestions = BRANCHES[BRANCH_ID.CAPACITY].questions

      const capacityStartQuestion = getFirstEligibleQuestion(
        capacityBranchQuestions
      )(state)

      nextQuestion = capacityStartQuestion
    }

    return nextQuestion
  }
)

export const getPathForOption = (option) =>
  createSelector([getNextQuestion, identity], (nextQuestionOnBranch, state) => {
    let nextQuestion = nextQuestionOnBranch
    /*
      Where the value of branchTo is a function, it will create a selector
      that returns a value from the state, otherwise it will just be a string
    */
    const branchId =
      typeof option.branchTo == 'function'
        ? option.branchTo(state)
        : option.branchTo

    if (branchId) {
      const newBranchQuestionIds = BRANCHES[branchId].questions

      const newBranchStartQuestion =
        getFirstEligibleQuestion(newBranchQuestionIds)(state)

      nextQuestion = newBranchStartQuestion
    }

    const earlyReturn =
      typeof option.earlyReturn == 'function'
        ? option.earlyReturn(state)
        : option.earlyReturn

    if (earlyReturn) {
      const capacityBranchQuestions = BRANCHES[BRANCH_ID.CAPACITY].questions

      const capacityStartQuestion = getFirstEligibleQuestion(
        capacityBranchQuestions
      )(state)

      nextQuestion = capacityStartQuestion
    }

    if (!nextQuestion) {
      return `/${PATHS.PREFIX}/${PATHS.BENEFITS}`
    }

    return `/${PATHS.PREFIX}/${nextQuestion.path}`
  })

export const isAtStartOfFlow = createSelector(
  [getOnboardingAnswers, getOnboardingState, getQuestionIndex],
  (answers, { branchId }, questionIndex) => {
    const noAnswersYet = !answers || isEmpty(answers)
    return noAnswersYet && branchId === BRANCH_ID.MAIN && questionIndex === 0
  }
)

export const isAtEndOfFlow = createSelector(
  [getOnboardingState, getNextQuestion],
  ({ branchId }, nextQuestion) => !!branchId && !nextQuestion
)

export const isBespokeCapacityCheckRequired = (state) => {
  return state.hasAccessibilityQuestionsEnabled || false
}
