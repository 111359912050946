const initialState = {}

export const referralParams = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_REFERRAL_PARAMS':
      return payload

    default:
      return state
  }
}
