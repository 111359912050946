import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { HeadInit } from 'ui/common/helpers'
import ModalLayout from 'ui/common/ModalLayout'
import Header from 'ui/common/header'
import Alert from 'ui/common/Alert'
import DecorativeBackground from 'ui/common/DecorativeBackground'
import CookieBanner from 'ui/common/CookieBanner'
import Loader from 'ui/common/loader'
import Login from 'ui/routes/login/views/root'
import Footer from 'ui/common/Footer'
import { getQueryParameters } from 'ui/common/helpers'
import { DEFAULT_TREATMENT } from '@/common/lib/split/constants'
import { LayoutConfigType } from 'types'
import { MAIN_CONTENT } from 'lib/zIndex'
import {
  FLAG_NAME,
  TREATMENTS,
} from '@/common/lib/split/experiments/ExptLinearWillsFlow/constants'
import { useSplitTreatment } from '@/client/hooks/useSplitTreatment'

const StyledRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: ${MAIN_CONTENT};

  ${({ $isScrollDisabled }) =>
    $isScrollDisabled &&
    `
    height: 100vh;
    overflow: hidden;
    position: fixed;
  `}
`

const commonWrapperStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  ${({ $verticallyCentered }) =>
    `justify-content: ${$verticallyCentered ? 'center' : 'flex-start'};`}
`

const StyledOuterContentWrapper = styled.div`
  ${commonWrapperStyles}
  ${({ $background }) => `background-color: ${$background}`};
`

const StyledInnerContentWrapper = styled(Wrapper)`
  ${commonWrapperStyles}

  --content-wrapper-safe-area-gap: ${GTR.XS};
  position: relative;
  width: auto; // TODO: Remove once we've fixed WILLS-819
  z-index: 2;
`

const Content = ({ children, hasAccessToContent, showContent }) => {
  if (hasAccessToContent && showContent) {
    return children
  } else if (hasAccessToContent) {
    return <Loader visible />
  } else {
    return <Login />
  }
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  hasAccessToContent: PropTypes.bool.isRequired,
  showContent: PropTypes.bool.isRequired,
}

const MainLayout = ({
  children,
  config,
  isCookieBannerFeatureEnabled,
  isLoggedIn,
  isLoggedInWillsUser,
  isProduction,
  isModalOpen,
  redirectToOnboardingFlow,
  rootUrl,
  showAlert,
  trackUser,
  userExptLinearWillsFlowTreatment,
  saveTreatment,
  userId,
}) => {
  const location = useLocation()
  const { isReady, isTimedout, treatment } = useSplitTreatment(
    FLAG_NAME,
    userId
  )

  useEffect(() => {
    if (isLoggedInWillsUser) {
      trackUser()
    }
  }, [isLoggedInWillsUser, trackUser])

  useEffect(() => {
    const isLoggedInNonWillsUser = isLoggedIn && !isLoggedInWillsUser

    if (isLoggedInNonWillsUser && !config.isPublic) {
      redirectToOnboardingFlow()
    }
  }, [
    config.isPublic,
    isLoggedIn,
    isLoggedInWillsUser,
    redirectToOnboardingFlow,
  ])

  useEffect(() => {
    const { successAlert, errorAlert } = getQueryParameters(location)
    if (successAlert || errorAlert) {
      showAlert({ successAlert, errorAlert })
    }
  }, [])

  useEffect(() => {
    // If the user is not logged in we have no user to save the treatment on.
    if (!userId) {
      return
    }

    // Don't save treatment if Split has not evaluated it yet / or if Split
    // SDK did not initalise correctly (in the case of SDK timeouts).
    if (treatment === DEFAULT_TREATMENT) {
      return
    }

    // Don't save treatments again if we've already saved it, unless we
    // previously saved the default 'control' treatment (which could have
    // occured as a result of a temporary issue with Split).
    if (
      userExptLinearWillsFlowTreatment &&
      userExptLinearWillsFlowTreatment !== DEFAULT_TREATMENT
    ) {
      return
    }

    /** Only call saveTreatment if treatment not saved to user yet */
    const timeoutId = setTimeout(() => {
      console.debug('[MainLayout] saving treatment', {
        treatment,
      })
      saveTreatment({ exptLinearWillsFlow: treatment })
    }, 0)

    return () => clearTimeout(timeoutId)
  }, [userId, userExptLinearWillsFlowTreatment, treatment, saveTreatment])

  if (!isReady && !isTimedout) {
    return <Loader visible />
  }

  const hasAccessToContent = isLoggedInWillsUser || !!config.isPublic
  const loggedInContentReady = isLoggedInWillsUser && (isReady || isTimedout)

  const isInGuidedFlow =
    config.routeInGuidedFlow && treatment === TREATMENTS.Experiment

  const background =
    config.backgroundColor ||
    (treatment === TREATMENTS.Experiment ? COLOR.WHITE : COLOR.BACKGROUND.FOG)

  const useDecorativeBackground =
    config.useDecorativeBackground && treatment === TREATMENTS.Experiment

  return (
    <>
      <ModalLayout />
      <Alert />
      <StyledRoot
        $isScrollDisabled={isModalOpen}
        // TODO: Once we've upgraded to React 18 we can hopefully avoid the
        // need for this weird syntax required for us to be able to use the
        // inert attribute. See https://camchenry.com/blog/how-to-disable-ui-and-control-focus-with-the-inert-attribute
        inert={isModalOpen ? 'inert' : undefined}
      >
        <HeadInit
          config={config}
          isProduction={isProduction}
          rootUrl={rootUrl}
        />
        {!isCookieBannerFeatureEnabled && <CookieBanner />}
        <Header
          exptLinearWillsFlowTreatment={treatment}
          hideBorder={hasAccessToContent ? config.hideBorder : true}
          hideNavigation={config.hideNavigation || isInGuidedFlow}
          isInGuidedFlow={isInGuidedFlow}
          isPublic={config.isPublic}
          useDecorativeBackground={isInGuidedFlow || useDecorativeBackground}
        />

        <StyledOuterContentWrapper
          $background={background}
          $verticallyCentered={config.centerContentVertically}
        >
          {useDecorativeBackground && <DecorativeBackground />}
          <StyledInnerContentWrapper
            noTrim
            $verticallyCentered={
              config.centerContentVertically || !isLoggedInWillsUser
            }
            margin={[0, 'var(--content-wrapper-safe-area-gap)']}
          >
            <Content
              hasAccessToContent={hasAccessToContent}
              showContent={isLoggedInWillsUser ? loggedInContentReady : true}
            >
              {children}
            </Content>
          </StyledInnerContentWrapper>
        </StyledOuterContentWrapper>
        {!config.hideFooter && <Footer />}
      </StyledRoot>
    </>
  )
}

MainLayout.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isLoggedInWillsUser: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  config: PropTypes.shape(LayoutConfigType),
  rootUrl: PropTypes.string.isRequired,
  isProduction: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  showAlert: PropTypes.func.isRequired,
  trackUser: PropTypes.func.isRequired,
  saveTreatment: PropTypes.func.isRequired,
  redirectToOnboardingFlow: PropTypes.func.isRequired,
  isCookieBannerFeatureEnabled: PropTypes.bool.isRequired,
  userExptLinearWillsFlowTreatment: PropTypes.string,
  userId: PropTypes.string,
}

MainLayout.defaultProps = {
  config: {},
}

export default MainLayout
