export const showAccountMenu = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_ACCOUNT_MENU': {
      return !state
    }
    case 'CLOSE_ACCOUNT_MENU': {
      return false
    }
    default:
      return state
  }
}
