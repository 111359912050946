import history from 'startup/common/history'
import { getQueryParameters } from 'ui/common/helpers'
import { DIRECT, NONE } from 'lib/tracking/constants'
import { setReferralParams } from '@/common/redux/referralParams/actionCreators'
import { getParsedCookie } from '../common/lib/storage/cookies/helpers'
import { getCookieConfig } from '../common/lib/storage/cookies/config'

export const storeReferralParameters = (store) => {
  const { CookieNames } = getCookieConfig()
  let queryParameters = getQueryParameters(history.location)
  const storedReferralParameters = getParsedCookie(
    CookieNames.WILLS_REFERRAL_PARAMS
  )
  const referralParameters = {
    referrer: DIRECT,
    voucher: NONE,
    ...(queryParameters.utm_source && { referrer: queryParameters.utm_source }),
    ...(queryParameters.utm_campaign && {
      voucher: queryParameters.utm_campaign,
    }),
  }
  /** Every attribute in the willsReferralParams cookie is set as referralParameters */
  if (storedReferralParameters) {
    Object.assign(referralParameters, storedReferralParameters)
  }
  store.dispatch(setReferralParams(referralParameters))
}
