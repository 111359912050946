import React from 'react'
import PropTypes from 'prop-types'
import { Button, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import Modal from 'ui/common/modal'

const StyledBorderedButton = styled(Button.Bordered)`
  border: 2px solid ${COLOR.GREY.DARK};
  display: block;
  margin-left: auto;

  &:hover {
    border: 2px solid ${COLOR.GREY.DARK};
  }
`

const ConfirmModal = ({
  confirmCallback,
  removeCallback,
  confirmHandler,
  content,
  confirmText,
  title,
}) => (
  <Modal title={title}>
    <P>{content}</P>
    <StyledBorderedButton
      margin={['S', 0]}
      onClick={confirmHandler.bind(null, confirmCallback, removeCallback)}
    >
      {confirmText}
    </StyledBorderedButton>
  </Modal>
)

ConfirmModal.propTypes = {
  confirmCallback: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  removeCallback: PropTypes.func,
}

export default ConfirmModal
