import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

const MINIMUM_PASSWORD_LENGTH = 10

const StyledPasswordStrengthBar = styled.div`
  margin-top: -4px;
  height: 4px;
  background-color: ${COLOR.GREY.LIGHT};
  border-radius: 2px;
  overflow: hidden;
`

const StyledPasswordStrength = styled.div`
  height: 100%;
  transition: width 0.2s ease;

  ${({ $currentPasswordLength, $success }) => css`
    background-color: ${$success ? COLOR.STATE.SUCCESS : COLOR.STATE.ERROR};
    width: ${($currentPasswordLength / MINIMUM_PASSWORD_LENGTH) * 100}%;
  `}
`

const PasswordStrengthBar = ({ password }) => {
  const currentPasswordLength = password ? password.length : 0

  return (
    <StyledPasswordStrengthBar>
      <StyledPasswordStrength
        $currentPasswordLength={currentPasswordLength}
        $success={currentPasswordLength >= MINIMUM_PASSWORD_LENGTH}
      />
    </StyledPasswordStrengthBar>
  )
}

PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
}

export default PasswordStrengthBar
