import { trackInGA4 } from 'lib/tracking'

/**
 * Custom impression listener that pushes impression data directly to GA4 via
 * gtag.
 */
export class Ga4ImpressionListener {
  logImpression = ({ impression }) => {
    /*
      We usually read the config from the Redux state but we don't have
      access to it here. Instead we are reading it directly from
      `window.INITIAL_STATE`. The `RELEASE_ENV` never changes at runtime so
      this is safe to do.
    */
    if (window.INITIAL_STATE?.config?.RELEASE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.debug(
        '[Ga4ImpressionListener]',
        `${impression.treatment}: ${impression.feature}`
      )
    }

    trackInGA4('set', 'user_properties', {
      splitClientId: impression.keyName,
      [impression.feature]: impression.treatment,
    })

    // gtag won't actually push the updated user properties to GA until the next
    // event is sent. To ensure these user properties get flushed to GA servers
    // immediately, send an event containing impression data that might be
    // useful down the line.
    trackInGA4('event', 'split_impression', {
      key: impression.keyName,
      flag: impression.feature,
      treatment: impression.treatment,
    })
  }
}
