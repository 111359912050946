import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'
import { NavLink } from 'react-router-dom'

const BORDER_HEIGHT = GTR.XXS
const ACTIVE_NAVIGATION_LINK_STYLES = {
  '--tab-indicator-color': COLOR.ACCENT.SECONDARY,
}

const StyledLink = styled(NavLink)`
  text-decoration: none;
  position: relative;

  &:hover {
    --tab-indicator-color: ${alpha(COLOR.GREY.DARK, 0.15)};
    text-decoration: none;
  }

  &:active,
  &:focus {
    --tab-indicator-color: ${alpha(COLOR.GREY.DARK, 0.25)};
    outline: 0;
    text-decoration: none;
  }

  &::before {
    background-color: var(--tab-indicator-color);
    content: '';
    height: ${BORDER_HEIGHT};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  // increase touch area
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: calc(-1 * ${BORDER_HEIGHT});
    left: calc(-1 * ${GTR.XS});
    right: calc(-1 * ${GTR.XS});
  }
`

const NavigationLink = ({ className, to, exact, children }) => (
  <StyledLink
    activeStyle={ACTIVE_NAVIGATION_LINK_STYLES}
    className={className}
    exact={exact}
    to={to}
  >
    {children}
  </StyledLink>
)

NavigationLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  exact: PropTypes.bool,
}

NavigationLink.defaultProps = {
  className: '',
  exact: true,
}

export default NavigationLink
