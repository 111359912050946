export const singleSignOn = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SINGLE_SIGN_ON': {
      const updatedState = { initiated: true, returnTo: action.returnTo }
      return Object.assign({}, state, updatedState)
    }
    default:
      return state
  }
}
