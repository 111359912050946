import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { H, P, Button, Wrapper, Input } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { Field } from 'formik'

import PasswordInput from 'ui/common/PasswordInput'
import { Head } from 'ui/common/helpers'
import FormikForm from 'ui/common/form/FormikForm'
import FormStatus from 'ui/common/FormStatus'

import { schema } from './validation'
import { FIELD_NAMES } from './constants'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled.a`
  font-weight: ${FONT.WEIGHT.BOLD};
  padding-left: ${GTR.XXS};
`

const LogIn = ({
  trackClickGa4,
  handleSubmit,
  marketingSiteUrl,
  redirectLoggedInUsers,
}) => {
  useEffect(() => {
    redirectLoggedInUsers()
  }, [])

  return (
    <StyledWrapper>
      <Head
        descriptionTag="Get started in seconds and finish your will when it suits you."
        titleTag="Log in"
      />

      <Wrapper centered container maxWidth={600}>
        <FormikForm
          catchApiError
          initialValues={{
            [FIELD_NAMES.EMAIL]: '',
            [FIELD_NAMES.PASSWORD]: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <>
              <FormStatus errors={errors} isSubmitting={isSubmitting} />
              <H decorative size="L" tag="h1">
                Log in
              </H>
              <P margin={[0, 0, 'L', 0]}>
                Don’t have an account?{' '}
                <StyledLink href={`${marketingSiteUrl}`} type="link">
                  Explore our services here
                </StyledLink>
              </P>
              <Wrapper>
                <Field
                  as={Input}
                  autoComplete="email"
                  error={errors[FIELD_NAMES.EMAIL]}
                  id={FIELD_NAMES.EMAIL}
                  label="Email"
                  name={FIELD_NAMES.EMAIL}
                  type="email"
                />
                <Field
                  as={PasswordInput}
                  error={errors[FIELD_NAMES.PASSWORD]}
                  id={FIELD_NAMES.PASSWORD}
                  label="Password"
                  name={FIELD_NAMES.PASSWORD}
                />
              </Wrapper>
              <Wrapper centered margin={['M', 0, 0]}>
                <Button.Primary
                  wide
                  loading={isSubmitting}
                  type="submit"
                  onClick={() => {
                    trackClickGa4('login_attempt')
                  }}
                >
                  Log in
                </Button.Primary>
              </Wrapper>
            </>
          )}
        </FormikForm>
        <Wrapper centered margin={['L', 0]}>
          <Link to="/request-reset-password">Forgot your password?</Link>
        </Wrapper>
      </Wrapper>
    </StyledWrapper>
  )
}

LogIn.propTypes = {
  trackClickGa4: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirectLoggedInUsers: PropTypes.func.isRequired,
  marketingSiteUrl: PropTypes.string,
}

export default LogIn
