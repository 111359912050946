import isUndefined from 'lodash/isUndefined'
import isDate from 'lodash/isDate'
import moment from 'moment'
import { dateTransform } from './utilities'

export const hasPartnerStatus = function (status) {
  return [
    'living_with_partner',
    'married',
    'widowed_and_remarried',
    'civil_partnership',
    'engaged',
  ].includes(status)
}

/**
 * Check whether a user is over eighteen
 *
 * @param {object} data Object containing DOB value
 */
export const isOverEighteen = function (data = {}) {
  if (data.DOB) {
    if (!isDate(data.DOB)) data = dateTransform('to_date', 'DOB')(data)
    return moment(moment().subtract(18, 'years')).diff(data.DOB) >= 0
  } else {
    return isUndefined(data.isOverEighteen) ? true : data.isOverEighteen
  }
}

export class ClientError extends Error {
  constructor(name, reason, data, id) {
    super(reason)
    this.id = id || null
    this.name = name
    this.reason = reason
    this.data = data
  }
}

export const childrenFilter = (dependant) => dependant.childOf
export const petsFilter = (dependant) => !dependant.childOf

const hasChildren = (dependants) => dependants.some(childrenFilter)
const hasPets = (dependants) => dependants.some(petsFilter)

export const childrenOrPets = (dependants) => {
  if (hasChildren(dependants) && hasPets(dependants)) {
    return ' children and pets'
  }
  if (hasChildren(dependants)) {
    return ' children'
  }
  return ' pets'
}

/**
 * Format dates for display, giving trimmed date, and isInvalid
 *
 * @param {rawDate} object describing date:
 * {
 *   day,
 *   month,
 *   year
 * }
 */
export const dateValueFormats = (rawDate = {}) => {
  const trimmed = {
    day: (rawDate.day ? rawDate.day + '' : '').substring(0, 2),
    month: (rawDate.month ? rawDate.month + '' : '').substring(0, 2),
    year: (rawDate.year ? rawDate.year + '' : '').substring(0, 4),
  }

  const momentIndexDate = Object.assign({}, rawDate)
  const defaultDate = { day: 31, month: 0, year: 1990 }

  if (rawDate.month) {
    momentIndexDate.month = parseInt(rawDate.month, 10) - 1
  }

  const dateWithDefaults = moment(
    Object.assign({}, defaultDate, momentIndexDate)
  )
  const day =
    !!rawDate.day &&
    (rawDate.day > 31 || (rawDate.month < 12 && !dateWithDefaults.isValid()))
  const month = !!rawDate.month && !day && !dateWithDefaults.isValid()
  const year =
    !!rawDate.year &&
    !day &&
    !month &&
    (!dateWithDefaults.isValid() || moment().diff(dateWithDefaults) < 0)

  return {
    rawDate,
    trimmed,
    isInvalidKey: {
      day,
      month,
      year,
    },
  }
}

export const transformHelpUrl = (url, userHasZendeskId) => {
  if (!userHasZendeskId) return url
  return '/sso/zendesk?return_to=' + url
}

export const hasRequest = (state, request) => {
  return state.requestsPending.some(({ name }) => name === request)
}

export const hasSomeRequest = (state, requests) => {
  return requests.some((request) => hasRequest(state, request))
}
