import PropTypes from 'prop-types'

// TODO: Once we move to TS, we can use the IStatus interface from Split SDK
// instead of this custom type.
export const SplitIStatusType = {
  isReady: PropTypes.bool,
  isReadyFromCache: PropTypes.bool,
  isTimedout: PropTypes.bool,
  hasTimedout: PropTypes.bool,
  isDestroyed: PropTypes.bool,
  lastUpdate: PropTypes.number,
}

export const SplitTreatmentAndStatusType = {
  ...SplitIStatusType,
  treatment: PropTypes.string,
}
