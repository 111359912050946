import { getQueryParameters, isInternalReferral } from 'ui/common/helpers'
import {
  SEARCH_ENGINES,
  NONE,
  DIRECT,
  ORGANIC,
  REFERRAL,
} from 'lib/tracking/constants'

export const gclidIsValid = (gclid) => new Date().getTime() < gclid?.expiryDate

const getReferrerDomainName = () => {
  if (!document.referrer) return null

  try {
    const { hostname } = new URL(document.referrer)
    return hostname.replace(/^(www\.)/, '')
  } catch {
    return null
  }
}

// Note: Logic here is a partial copy of some code from .com. See
// `getReferralSource` in https://github.com/farewill/farewill.com/blob/main/src/lib/tracking/trackingParamsHelpers.ts#L33
const getTrackingParams = (location) => {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_channel,
    utm_term,
    utm_content,
    gclid,
  } = getQueryParameters(location)

  const googleClickId = gclidIsValid(gclid) ? gclid.value : NONE

  const trackingParams = {
    utmSource: utm_source || NONE,
    utmMedium: utm_medium || NONE,
    utmCampaign: utm_campaign || NONE,
    utmTerm: utm_term || NONE,
    utmContent: utm_content || NONE,
    utmChannel: utm_channel || NONE,
    googleClickId,
    landingPath: window.location.pathname,
  }

  if (trackingParams.utmSource !== NONE) {
    return trackingParams
  }

  const referrerDomainName = getReferrerDomainName()

  if (referrerDomainName) {
    const internalReferral = isInternalReferral(referrerDomainName)
    const searchEngine = SEARCH_ENGINES.find((name) =>
      new RegExp(name, 'i').test(referrerDomainName)
    )

    if (searchEngine) {
      return {
        ...trackingParams,
        utmSource: searchEngine,
        utmMedium: ORGANIC,
      }
    }

    if (!internalReferral) {
      return {
        utmSource: referrerDomainName,
        utmMedium: REFERRAL,
      }
    }
  }

  return { ...trackingParams, utmSource: DIRECT, utmMedium: NONE }
}

export default getTrackingParams
