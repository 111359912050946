export const topOfPageError = (
  state = {},
  { type, id, errorMessage, inlineErrorMessage }
) => {
  switch (type) {
    case 'SHOW_TOP_OF_PAGE_ERROR':
      return {
        id,
        errorMessage,
        inlineErrorMessage,
      }

    case 'REMOVE_TOP_OF_PAGE_ERROR':
      return {}

    default:
      return state
  }
}
