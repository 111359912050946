import { connect } from 'react-redux'
import get from 'lodash/get'
import history from 'startup/common/history'

import { methodCallPromise } from 'startup/common/actionCreators'
import { showAlert } from '@/common/redux/alert/actionCreators'
import { showModal, closeModal } from '@/common/redux/modal/actionCreators'
import { trackEvent } from '@/common/redux/tracking/actionCreators'
import { transformHelpUrl } from 'startup/common/helpers'
import ConfirmModal from 'ui/common/ConfirmModal'
import PolicyUpdatedModal from 'ui/common/PolicyUpdatedModal'
import {
  closeMobileNav,
  toggleMobileNav,
} from '@/common/redux/showMobileNav/actionCreators'
import {
  closeAccountMenu,
  toggleAccountMenu,
} from '@/common/redux/showAccountMenu/actionCreators'
import { getShowAccountMenu } from '@/common/redux/showAccountMenu/selectors'
import { setUserPolicyAcceptance } from '@/common/redux/user/actionCreators'
import { getProducts } from '@/common/redux/products/selectors'
import { isLoggedIn } from '@/common/redux/account/selectors'
import { getBranding, isLoggedInWillsUser } from '@/common/redux/user/selectors'

import Header from './component'

const mapStateToProps = (state, ownProps) => {
  const userHasZendeskId = !!get(state, ['user', 'zendeskId'])
  const requestsUrl = transformHelpUrl(
    state.config.ZENDESK_REQUESTS_URL,
    userHasZendeskId
  )

  return {
    branding: getBranding(state),
    showMobileNav: state.showMobileNav,
    showAccountMenu: getShowAccountMenu(state),
    loggedIn: isLoggedIn(state),
    loggedInWillsUser: isLoggedInWillsUser(state),
    termsAcceptedAt: state.user.termsAcceptedAt,
    privacyPolicyAcceptedAt: state.user.privacyPolicyAcceptedAt,
    modalIsOpen: state.modal.hide === false,
    terms: state.config.TERMS,
    privacy: state.config.PRIVACY,
    userHasZendeskId,
    requestsUrl,
    marketingSiteUrl: state.config.MARKETING_SITE_URL,
    accountAppUrl: state.config.ACCOUNT_APP_URL,
    products: getProducts(state),
    useDecorativeBackground: ownProps.useDecorativeBackground,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMobileNav: () => dispatch(toggleMobileNav()),
    toggleAccountMenu: (currentlyOpen) => {
      const event = currentlyOpen ? 'close_account_menu' : 'open_account_menu'
      dispatch(toggleAccountMenu())
      dispatch(trackEvent(event))
    },
    openTermsModal: (termsUpdated, privacyUpdated, marketingSiteUrl) => {
      dispatch(
        showModal({
          component: PolicyUpdatedModal,
          termsUpdated,
          privacyUpdated,
          marketingSiteUrl,
          keepOpen: true,
          onAccept: (policiesAccepted) => {
            dispatch(methodCallPromise('policies/accept', policiesAccepted))
              .then((timestamps) =>
                dispatch(setUserPolicyAcceptance(timestamps))
              )
              .then(() => dispatch(closeModal()))
              .catch((err) => dispatch(showAlert('error', err)))
          },
        })
      )
    },
    logout: () => {
      dispatch(closeMobileNav())
      dispatch(closeAccountMenu())
      dispatch(
        showModal({
          component: ConfirmModal,
          title: 'Are you sure you want to logout?',
          content: 'All your details will be saved for when you next log in.',
          confirmText: 'Yes, logout',
          confirmCallback: () => history.push('/logout'),
        })
      )
    },
    trackInviteClick: () => {
      dispatch(
        trackEvent('invite', {
          source: 'header',
        })
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
