import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, P } from '@farewill/ui'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'
import { darken } from '@farewill/ui/helpers/colors'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { setCookie, getCookie } from 'lib/storage/cookies/helpers'
import { getCookieConfig } from 'lib/storage/cookies/config'
import MarketingSiteLink from 'ui/common/MarketingSiteLink'

import './styles.styl'

// remove the &&& {} once the bug that sets padding in ui is fixed
const StyledCloseButton = styled(Button)`
  &&& {
    padding: ${GTR.XS} ${GTR.S};
  }
  color: inherit;
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  background: ${COLOR.GREY.LIGHT};
  &:hover {
    background: ${darken(COLOR.GREY.LIGHT, 0.2)};
  }
`

const StyledText = styled(P)`
  font-size: ${FONT.SIZE.XS};

  ${screenMin.l`
    font-size: ${FONT.SIZE.S};
  `}
`

class CookieBanner extends Component {
  constructor(props) {
    super(props)

    const { CookieNames } = getCookieConfig()
    this.state = {
      cookieBannerHidden: getCookie(CookieNames.HIDE_COOKIE_BANNER === 'true'),
    }
  }

  handleCloseButtonClick = () => {
    const { CookieNames } = getCookieConfig()
    setCookie(CookieNames.HIDE_COOKIE_BANNER, true, { expires: 365 })
    this.setState({ cookieBannerHidden: true })
  }

  render() {
    const { cookieBannerHidden } = this.state

    if (cookieBannerHidden) {
      return null
    }

    return (
      <div className="c-cookie-banner">
        <div className="c-cookie-banner-container">
          <StyledText margin={0}>
            Farewill uses cookies to make the site simpler.{' '}
            <MarketingSiteLink path="/privacy">
              Find out more about cookies
            </MarketingSiteLink>
          </StyledText>

          <StyledCloseButton onClick={this.handleCloseButtonClick}>
            Close
          </StyledCloseButton>
        </div>
      </div>
    )
  }
}

export default CookieBanner
