import { formatQueryObject } from 'lib/formatting/query'

export const addToQuery = (query) => ({
  type: 'ADD_TO_QUERY',
  payload: formatQueryObject(query),
})

export const setQuery = (query) => ({
  type: 'SET_QUERY',
  payload: formatQueryObject(query),
})
