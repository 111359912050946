import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(
    /* webpackChunkName: "RequestResetPassword" */ 'ui/routes/request-reset-password/routes'
  )

const RequestResetPasswordRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

RequestResetPasswordRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType).isRequired,
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

RequestResetPasswordRouteHandler.displayName =
  'RequestResetPasswordRouteHandler'

export default RequestResetPasswordRouteHandler
