import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { screenMin } from '@farewill/ui/helpers/responsive'

import MenuModal from 'ui/common/MenuModal'

import { HEADER as HEADER_Z_INDEX } from 'lib/zIndex'
import NavigationHeader from './components/NavigationHeader'
import MobileMenu from './components/MobileMenu'
import AccountMenu from './components/AccountMenu'

const StyledNavigationHeader = styled(NavigationHeader)`
  ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen &&
    `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  `};
  z-index: ${HEADER_Z_INDEX};
`

const StyledMenu = styled(MobileMenu)`
  ${screenMin.l`
    display: none;
  `};
`

const StyledModal = styled(MenuModal)`
  ${screenMin.l`
    display: none;
  `};
`

const termsOrPrivacyHasBeenUpdated = (
  termsAcceptedAt,
  privacyPolicyAcceptedAt,
  termsDate,
  privacyDate
) => {
  const termsAcceptedAtDate = new Date(termsAcceptedAt).getTime()
  const privacyAcceptedAtDate = new Date(privacyPolicyAcceptedAt).getTime()
  return termsAcceptedAtDate < termsDate || privacyAcceptedAtDate < privacyDate
}

const Header = ({
  branding,
  exptLinearWillsFlowTreatment,
  logout,
  toggleMobileNav,
  toggleAccountMenu,
  showMobileNav,
  showAccountMenu,
  trackInviteClick,
  requestsUrl,
  termsAcceptedAt,
  privacyPolicyAcceptedAt,
  loggedIn,
  loggedInWillsUser,
  openTermsModal,
  terms,
  privacy,
  marketingSiteUrl,
  userHasZendeskId,
  hideNavigation,
  isInGuidedFlow,
  isPublic,
  hideBorder,
  accountAppUrl,
  products,
  useDecorativeBackground,
}) => {
  useEffect(() => {
    if (loggedInWillsUser && !isPublic) {
      const termsDate = new Date(terms.createdAt).getTime()
      const privacyDate = new Date(privacy.createdAt).getTime()
      if (
        !(termsAcceptedAt && privacyPolicyAcceptedAt) ||
        termsOrPrivacyHasBeenUpdated(
          termsAcceptedAt,
          privacyPolicyAcceptedAt,
          termsDate,
          privacyDate
        )
      ) {
        const termsUpdated =
          !termsAcceptedAt || new Date(termsAcceptedAt).getTime() < termsDate
        const privacyUpdated =
          !privacyPolicyAcceptedAt ||
          new Date(privacyPolicyAcceptedAt).getTime() < privacyDate
        openTermsModal(termsUpdated, privacyUpdated, marketingSiteUrl)
      }
    }
  }, [])

  const navigationHeaderWrapper = useRef(null)

  const [menuOffsetTop, setMenuOffsetTop] = useState()
  const [menuOffsetRight, setMenuOffsetRight] = useState()

  const calculateMenuOffsetRight = (wrapper) => {
    const navContainer = wrapper.querySelector('.navigation-container')
    const navContainerStyle = window.getComputedStyle(navContainer)
    const padding = parseFloat(navContainerStyle.paddingRight)
    const viewportWidth = window.innerWidth
    const distanceToRightViewportEdge =
      viewportWidth - (navContainer?.offsetLeft + navContainer?.offsetWidth)
    return `${padding + distanceToRightViewportEdge}px`
  }

  const onAccountMenuButtonClick = () => {
    const wrapper = navigationHeaderWrapper.current
    if (wrapper) {
      setMenuOffsetTop(`${wrapper.offsetTop + wrapper.clientHeight}px`)
      setMenuOffsetRight(calculateMenuOffsetRight(wrapper))
    }
    toggleAccountMenu(showAccountMenu)
  }

  const onMobileMenuButtonClick = () => {
    const wrapper = navigationHeaderWrapper.current
    if (wrapper) {
      setMenuOffsetTop(`${wrapper.clientHeight}px`)
    }
    toggleMobileNav()
  }

  useEffect(() => {
    const handleResize = () => {
      const wrapper = navigationHeaderWrapper.current
      if (wrapper && showAccountMenu) {
        const newOffSet = calculateMenuOffsetRight(wrapper)
        if (menuOffsetRight !== newOffSet) {
          setMenuOffsetRight(newOffSet)
        }
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [showAccountMenu])

  const navigationLinks = loggedInWillsUser
    ? [
        {
          name: 'Your progress',
          url: '/wills',
        },
        {
          name: 'Your will',
          url: '/summary',
        },
        {
          name: 'Who’s in your will',
          url: '/people',
        },
      ]
    : []

  const hasOnlineWillAndAnotherProduct =
    products?.includes('online_will') && products?.length > 1

  const accountLinks = [
    {
      url: accountAppUrl,
      name: 'Your services',
      external: true,
      dividerBelow: true,
    },
    ...(hasOnlineWillAndAnotherProduct
      ? [
          {
            url: `${accountAppUrl}/settings`,
            name: 'Account settings',
            external: true,
          },
          {
            url: '/account',
            name: 'Will settings',
          },
        ]
      : [
          {
            url: '/account',
            name: 'Account settings',
          },
        ]),
    ...(loggedInWillsUser
      ? [
          {
            url: requestsUrl,
            name: 'Messages',
            external: !userHasZendeskId,
          },
          {
            url: '/invite',
            name: 'Invite',
            onClick: trackInviteClick,
          },
        ]
      : []),
  ]

  return (
    <>
      <StyledNavigationHeader
        ref={navigationHeaderWrapper}
        accountAppUrl={accountAppUrl}
        branding={branding}
        exptLinearWillsFlowTreatment={exptLinearWillsFlowTreatment}
        hideBorder={hideBorder}
        hideNavigation={hideNavigation}
        isAccountMenuOpen={showAccountMenu}
        isInGuidedFlow={isInGuidedFlow}
        isMobileMenuOpen={showMobileNav}
        loggedIn={loggedIn}
        marketingSiteUrl={marketingSiteUrl}
        navigationLinks={navigationLinks}
        useDecorativeBackground={useDecorativeBackground}
        onAccountMenuButtonClick={onAccountMenuButtonClick}
        onMobileMenuButtonClick={onMobileMenuButtonClick}
      />

      {showMobileNav && (
        <StyledModal
          close={toggleMobileNav}
          offsets={{
            top: menuOffsetTop,
          }}
        >
          <StyledMenu
            accountLinks={accountLinks}
            logout={logout}
            navigationLinks={navigationLinks}
          />
        </StyledModal>
      )}

      {showAccountMenu && (
        <AccountMenu
          accountLinks={accountLinks}
          logout={logout}
          offsets={{
            top: menuOffsetTop,
            right: menuOffsetRight,
          }}
        />
      )}
    </>
  )
}

Header.propTypes = {
  branding: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  exptLinearWillsFlowTreatment: PropTypes.string,
  loggedIn: PropTypes.bool,
  loggedInWillsUser: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  showMobileNav: PropTypes.bool,
  showAccountMenu: PropTypes.bool,
  toggleMobileNav: PropTypes.func.isRequired,
  toggleAccountMenu: PropTypes.func.isRequired,
  trackInviteClick: PropTypes.func.isRequired,
  requestsUrl: PropTypes.string.isRequired,
  termsAcceptedAt: PropTypes.string,
  privacyPolicyAcceptedAt: PropTypes.string,
  openTermsModal: PropTypes.func.isRequired,
  terms: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  privacy: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  marketingSiteUrl: PropTypes.string.isRequired,
  userHasZendeskId: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  isInGuidedFlow: PropTypes.bool,
  useDecorativeBackground: PropTypes.bool,
  isPublic: PropTypes.bool,
  hideBorder: PropTypes.bool,
  accountAppUrl: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.string),
}

Header.defaultProps = {
  loggedIn: false,
  showMobileNav: false,
  showAccountMenu: false,
  termsAcceptedAt: '',
  privacyPolicyAcceptedAt: '',
  userHasZendeskId: false,
  hideNavigation: false,
  isInGuidedFlow: false,
  isPublic: false,
  hideBorder: false,
  useDecorativeBackground: false,
}

export default Header
