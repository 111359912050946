import PropTypes from 'prop-types'

export const LayoutConfigType = {
  backgroundColor: PropTypes.string,
  isPublic: PropTypes.bool,
  noIndex: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideBorder: PropTypes.bool,
  centerContentVertically: PropTypes.bool,
  routeInGuidedFlow: PropTypes.bool,
}
