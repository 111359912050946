import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

import ClickOffWrapper from './components/ClickOffWrapper'

const OFFSETS_OVERRIDES = css`
  ${({ offsets }) =>
    Object.entries(offsets).reduce(
      (memo, [direction, value]) => `${memo} ${direction}: ${value};`,
      ''
    )};
`

const StyledModal = styled.div`
  position: absolute;
  z-index: 100;

  &,
  &::before,
  & > * {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${OFFSETS_OVERRIDES};
  }

  &::before,
  & > * {
    position: fixed;
  }

  &::before {
    content: '';
    pointer-events: none;
    opacity: 0.9;
    background-color: ${COLOR.GREY.DARK};
  }
`

const StyledContentWrapper = styled.div`
  bottom: unset;
  overflow: auto;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`

const MenuModal = ({ className, children, close, offsets }) => (
  <StyledModal className={className} offsets={offsets}>
    <ClickOffWrapper close={close} offsets={offsets} />
    <StyledContentWrapper aria-modal="true" offsets={offsets} role="dialog">
      {children}
    </StyledContentWrapper>
  </StyledModal>
)

MenuModal.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
  offsets: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

MenuModal.defaultProps = {
  className: '',
  offsets: {},
}

export default MenuModal
