import React from 'react'
import PropTypes from 'prop-types'

const HeaderHomeLink = ({
  accountAppUrl,
  children,
  loggedIn,
  marketingSiteUrl,
}) => {
  const href = loggedIn ? accountAppUrl : `${marketingSiteUrl}/wills/overview`
  return <a href={href}>{children}</a>
}

HeaderHomeLink.propTypes = {
  accountAppUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  loggedIn: PropTypes.bool,
  marketingSiteUrl: PropTypes.string.isRequired,
}

HeaderHomeLink.defaultProps = {
  hideNavigation: false,
}

export default HeaderHomeLink
