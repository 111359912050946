import PropTypes from 'prop-types'

/**
 * For a more exhaustive type, refer to `RouteComponentProps` in
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-router/index.d.ts
 */
export const RoutePropsType = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
}
