const initialState = []

export const subscriptions = (state = initialState, { type, meta }) => {
  switch (type) {
    case 'REJOIN_SUBSCRIPTION_SUCCESS':
      return state.map((subscription) => {
        if (meta.userId !== subscription.userId) return subscription
        return { ...subscription, cancelAtPeriodEnd: false }
      })

    case 'CANCEL_SUBSCRIPTION_SUCCESS':
      return state.map((subscription) => {
        if (meta.userId !== subscription.userId) return subscription
        return { ...subscription, cancelAtPeriodEnd: true }
      })

    default:
      return state
  }
}
