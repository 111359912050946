const initialState = {}

export const query = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_QUERY':
      return payload

    case 'ADD_TO_QUERY':
      return { ...state, ...payload }

    default:
      return state
  }
}
