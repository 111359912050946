import without from 'lodash/without'

const petGuardian = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_PET_GUARDIAN':
      return action.guardianId
    default:
      return state
  }
}

export const petGuardians = (state = [], action) => {
  switch (action.type) {
    case 'ADD_PET_GUARDIAN':
      return [...state, petGuardian(undefined, action)]
    case 'REMOVE_PET_GUARDIAN':
      return without(state, action.guardianId)
    default:
      return state
  }
}

const pet = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_PET':
      if (action.id !== state._id) return state
      return action.data
    case 'ADD_PET_GUARDIAN':
      if (action.id !== state._id) return state
      return Object.assign({}, state, {
        guardianIds: petGuardians(state.guardianIds, action),
      })
    case 'REMOVE_PET_GUARDIAN':
      if (action.id !== state._id) return state
      return Object.assign({}, state, {
        guardianIds: petGuardians(state.guardianIds, action),
      })
    default:
      return state
  }
}

export const pets = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_PET':
      return state.map((b) => pet(b, action))
    case 'ADD_PET_GUARDIAN':
      return state.map((b) => pet(b, action))
    case 'REMOVE_PET_GUARDIAN':
      return state.map((b) => pet(b, action))
    default:
      return state
  }
}
