export const config = (state = {}, { type, payload }) => {
  switch (type) {
    case 'UPDATE_CONFIG':
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
