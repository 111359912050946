import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/**
 * [1] We need to set pointer-events to none on the outer container to prevent
 *     clicks on the padding from preventing the modal from being closed (click
 *     here should pass through to the Overlay, which will handle the dismiss).
 */
const StyledContainer = styled.div`
  max-width: calc(100% - (2 * var(--modal-gap-x)));
  pointer-events: none; // [1]
  width: auto;
`

/**
 * [1] We must re-enable pointer-events on the inner container to allow
 *     interaction with the modal's content.
 */
const StyledInnerContainer = styled.div`
  pointer-events: auto; // [1]
`

const ModalComponent = ({ children, show }) => {
  return (
    <StyledContainer $show={show}>
      <StyledInnerContainer>{children}</StyledInnerContainer>
    </StyledContainer>
  )
}

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
}

export default ModalComponent
