import { connect } from 'react-redux'

import { closeModal } from '@/common/redux/modal/actionCreators'

import Modal from './component'

const mapStateToProps = null

const mapDispatchToProps = {
  handleClose: closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
