export const linkedUserData = (state = {}, action) => {
  switch (action.type) {
    case 'CREATE_LINKED_USER':
      return {
        ...state,
        isLinkedUser: true,
        linkedUser: action.data.linkedUser,
      }
    case 'UPDATE_LINKED_USER':
      return {
        ...state,
        linkedUser: action.data.linkedUser,
      }
    default:
      return state
  }
}
