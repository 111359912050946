/**
 * The allowed values for traffic types we have setup in Split.
 * See https://help.split.io/hc/en-us/articles/360019916311-Traffic-type
 */
export const TRAFFIC_TYPES = {
  ANONYMOUS: 'anonymous',
  USER: 'user',
}

/**
 * Default treatment returned when either:
 * 1) SDK not ready to evaluate flag
 * 2) No targeting rules configured for flag
 * 3) SDK experiences an error while evaluating flag
 *
 * See https://help.split.io/hc/en-us/articles/360020528072-Control-treatment#:~:text=The%20control%20treatment%20is%20a,feature%20flag%20are%20not%20defined.
 */
export const DEFAULT_TREATMENT = 'control'
