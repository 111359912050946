export const beneficiary = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_BENEFICIARY': {
      if (action.id !== state._id) return state
      return action.data
    }
    case 'TOGGLE_BENEFICIARY_GUARDIAN': {
      if (action.id !== state._id) return state
      const existing = state.guardianIds.indexOf(action.guardianId) !== -1
      const guardianIds = existing
        ? state.guardianIds.filter((id) => id !== action.guardianId)
        : state.guardianIds.concat(action.guardianId)
      return Object.assign({}, state, { guardianIds })
    }
    case 'TOGGLE_BENEFICIARY_RESPONSIBILITY': {
      if (action.id !== state._id) return state
      const parentalResponsibilityState = state.parentalResponsibility || []
      const existingParents =
        parentalResponsibilityState.indexOf(action.childId) !== -1
      const parentalResponsibility = existingParents
        ? parentalResponsibilityState.filter((id) => id !== action.childId)
        : parentalResponsibilityState.concat(action.childId)
      return Object.assign({}, state, { parentalResponsibility })
    }
    default: {
      return state
    }
  }
}

export const beneficiaries = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_BENEFICIARY': {
      return state.map((b) => beneficiary(b, action))
    }
    case 'TOGGLE_BENEFICIARY_GUARDIAN': {
      return state.map((b) => beneficiary(b, action))
    }
    case 'TOGGLE_BENEFICIARY_RESPONSIBILITY': {
      return state.map((b) => beneficiary(b, action))
    }
    default: {
      return state
    }
  }
}
